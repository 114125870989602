import Icon from 'components/Icon';
import React from 'react';
import { __DEV__ } from 'utils';

import { SpinnerProps } from './types';

const Spinner = (props: SpinnerProps) => <Icon name="spinner" {...props} />;

if (__DEV__) {
  Spinner.displayName = 'Spinner';
}

export default Spinner;
export type { SpinnerProps };
