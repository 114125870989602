import Box from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

import useHeadingStyles from './styles';
import { HeadingProps } from './types';

const Heading = React.forwardRef((props: HeadingProps, ref?: React.Ref<HTMLElement>) => {
  // @ts-ignore - Union too complex
  const { as = 'h1', size, truncate, ...rest } = props;

  return <Box ref={ref} as={as} {...useHeadingStyles({ size, as, truncate })} {...rest} />;
});

if (__DEV__) {
  Heading.displayName = 'Heading';
}

export default Heading;
export type { HeadingProps };
