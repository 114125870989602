import { AlignContent } from './AlignContent';
import { AlignItems } from './AlignItems';
import { AlignSelf } from './AlignSelf';
import { Animation } from './Animation';
import { Appearance } from './Appearance';
import { BackdropFilter } from './BackdropFilter';
import { BackgroundAttachment } from './BackgroundAttachment';
import { BackgroundClip } from './BackgroundClip';
import { BackgroundColor } from './BackgroundColor';
import { BackgroundImage } from './BackgroundImage';
import { BackgroundOpacity } from './BackgroundOpacity';
import { BackgroundPosition } from './BackgroundPosition';
import { BackgroundRepeat } from './BackgroundRepeat';
import { BackgroundSize } from './BackgroundSize';
import { BorderCollapse } from './BorderCollapse';
import { BorderColor } from './BorderColor';
import { BorderOpacity } from './BorderOpacity';
import { BorderRadius } from './BorderRadius';
import { BorderStyle } from './BorderStyle';
import { BorderWidth } from './BorderWidth';
import { BoxShadow } from './BoxShadow';
import { BoxSizing } from './BoxSizing';
import { Clear } from './Clear';
import { Cursor } from './Cursor';
import { Display } from './Display';
import { DivideColor } from './DivideColor';
import { DivideOpacity } from './DivideOpacity';
import { DivideWidth } from './DivideWidth';
import { Fill } from './Fill';
import { Filter } from './Filter';
import { Flex } from './Flex';
import { FlexDirection } from './FlexDirection';
import { FlexGrow } from './FlexGrow';
import { FlexShrink } from './FlexShrink';
import { FlexWrap } from './FlexWrap';
import { Float } from './Float';
import { FontFamily } from './FontFamily';
import { FontSize } from './FontSize';
import { FontSmoothing } from './FontSmoothing';
import { FontStyle } from './FontStyle';
import { FontWeight } from './FontWeight';
import { Gap } from './Gap';
import { GradientColorStops } from './GradientColorStops';
import { GridAutoFlow } from './GridAutoFlow';
import { GridColumn } from './GridColumn';
import { GridColumnEnd } from './GridColumnEnd';
import { GridColumnStart } from './GridColumnStart';
import { GridRow } from './GridRow';
import { GridRowEnd } from './GridRowEnd';
import { GridRowStart } from './GridRowStart';
import { GridTemplateColumns } from './GridTemplateColumns';
import { GridTemplateRows } from './GridTemplateRows';
import { Group } from './Group';
import { Height } from './Height';
import { Inset } from './Inset';
import { JustifyContent } from './JustifyContent';
import { LetterSpacing } from './LetterSpacing';
import { LineHeight } from './LineHeight';
import { ListStylePosition } from './ListStylePosition';
import { ListStyleType } from './ListStyleType';
import { Margin } from './Margin';
import { MaxHeight } from './MaxHeight';
import { MaxWidth } from './MaxWidth';
import { MinHeight } from './MinHeight';
import { MinWidth } from './MinWidth';
import { ObjectFit } from './ObjectFit';
import { ObjectPosition } from './ObjectPosition';
import { Offset } from './Offset';
import { Opacity } from './Opacity';
import { Order } from './Order';
import { Outline } from './Outline';
import { Overflow } from './Overflow';
import { Overscroll } from './Overscroll';
import { Padding } from './Padding';
import { PlaceholderColor } from './PlaceholderColor';
import { PlaceholderOpacity } from './PlaceholderOpacity';
import { PointerEvents } from './PointerEvents';
import { Position } from './Position';
import { Resize } from './Resize';
import { Rotate } from './Rotate';
import { Scale } from './Scale';
import { Skew } from './Skew';
import { Space } from './Space';
import { Stroke } from './Stroke';
import { StrokeWidth } from './StrokeWidth';
import { TableLayout } from './TableLayout';
import { TextAlign } from './TextAlign';
import { TextColor } from './TextColor';
import { TextDecoration } from './TextDecoration';
import { TextOpacity } from './TextOpacity';
import { TextTransform } from './TextTransform';
import { Transform } from './Transform';
import { TransformOrigin } from './TransformOrigin';
import { TransitionDelay } from './TransitionDelay';
import { TransitionDuration } from './TransitionDuration';
import { TransitionProperty } from './TransitionProperty';
import { TransitionTimingFunction } from './TransitionTimingFunction';
import { Translate } from './Translate';
import { Truncate } from './Truncate';
import { UserSelect } from './UserSelect';
import { VerticalAlign } from './VerticalAlign';
import { Visibility } from './Visibility';
import { Whitespace } from './Whitespace';
import { Width } from './Width';
import { WordBreak } from './WordBreak';
import { ZIndex } from './ZIndex';

export interface LayoutProps {
  boxSizing?: BoxSizing | Array<BoxSizing>;
  display?: Display | Array<Display>;
  float?: Float | Array<Float>;
  clear?: Clear | Array<Clear>;
  objectFit?: ObjectFit | Array<ObjectFit>;
  objectPosition?: ObjectPosition | Array<ObjectPosition>;
  overflow?: Overflow | Array<Overflow>;
  overscroll?: Overscroll | Array<Overscroll>;
  position?: Position | Array<Position>;
  inset?: Inset | Array<Inset>;
  /** @deprecated use `inset` prop or `Inset` type instead */
  offset?: Offset | Array<Offset>;
  visibility?: Visibility | Array<Visibility>;
  zIndex?: ZIndex | Array<ZIndex>;
}

export interface FlexboxProps {
  flexDirection?: FlexDirection | Array<FlexDirection>;
  flexWrap?: FlexWrap | Array<FlexWrap>;
  alignItems?: AlignItems | Array<AlignItems>;
  alignContent?: AlignContent | Array<AlignContent>;
  alignSelf?: AlignSelf | Array<AlignSelf>;
  justifyContent?: JustifyContent | Array<JustifyContent>;
  flex?: Flex | Array<Flex>;
  flexGrow?: FlexGrow | Array<FlexGrow>;
  flexShrink?: FlexShrink | Array<FlexShrink>;
  order?: Order | Array<Order>;
}

export interface GridProps {
  gridTemplateColumns?: GridTemplateColumns | Array<GridTemplateColumns>;
  gridColumn?: GridColumn | Array<GridColumn>;
  gridColumnStart?: GridColumnStart | Array<GridColumnStart>;
  gridColumnEnd?: GridColumnEnd | Array<GridColumnEnd>;
  gridTemplateRows?: GridTemplateRows | Array<GridTemplateRows>;
  gridRow?: GridRow | Array<GridRow>;
  gridRowStart?: GridRowStart | Array<GridRowStart>;
  gridRowEnd?: GridRowEnd | Array<GridRowEnd>;
  gap?: Gap | Array<Gap>;
  gridAutoFlow?: GridAutoFlow | Array<GridAutoFlow>;
}

export interface SpacingProps {
  margin?: Margin | Array<Margin>;
  padding?: Padding | Array<Padding>;
  space?: Space | Array<Space>;
}

export interface SizingProps {
  width?: Width | Array<Width>;
  minWidth?: MinWidth | Array<MinWidth>;
  maxWidth?: MaxWidth | Array<MaxWidth>;
  height?: Height | Array<Height>;
  minHeight?: MinHeight | Array<MinHeight>;
  maxHeight?: MaxHeight | Array<MaxHeight>;
}

export interface TextProps {
  fontFamily?: FontFamily | Array<FontFamily>;
  fontSize?: FontSize | Array<FontSize>;
  fontSmoothing?: FontSmoothing | Array<FontSmoothing>;
  fontStyle?: FontStyle | Array<FontStyle>;
  fontWeight?: FontWeight | Array<FontWeight>;
  letterSpacing?: LetterSpacing | Array<LetterSpacing>;
  lineHeight?: LineHeight | Array<LineHeight>;
  listStyleType?: ListStyleType | Array<ListStyleType>;
  listStylePosition?: ListStylePosition | Array<ListStylePosition>;
  placeholderColor?: PlaceholderColor | Array<PlaceholderColor>;
  placeholderOpacity?: PlaceholderOpacity | Array<PlaceholderOpacity>;
  textAlign?: TextAlign | Array<TextAlign>;
  textColor?: TextColor | Array<TextColor>;
  textOpacity?: TextOpacity | Array<TextOpacity>;
  textDecoration?: TextDecoration | Array<TextDecoration>;
  textTransform?: TextTransform | Array<TextTransform>;
  verticalAlign?: VerticalAlign | Array<VerticalAlign>;
  whitespace?: Whitespace | Array<Whitespace>;
  wordBreak?: WordBreak | Array<WordBreak>;
  truncate?: Truncate | Array<Truncate>;
}

export interface BackgroundProps {
  backgroundAttachment?: BackgroundAttachment | Array<BackgroundAttachment>;
  backgroundClip?: BackgroundClip | Array<BackgroundClip>;
  backgroundColor?: BackgroundColor | Array<BackgroundColor>;
  backgroundImage?: BackgroundImage | Array<BackgroundImage>;
  backgroundOpacity?: BackgroundOpacity | Array<BackgroundOpacity>;
  backgroundPosition?: BackgroundPosition | Array<BackgroundPosition>;
  backgroundRepeat?: BackgroundRepeat | Array<BackgroundRepeat>;
  backgroundSize?: BackgroundSize | Array<BackgroundSize>;
  gradientColorStops?: GradientColorStops | Array<GradientColorStops>;
}

export interface BorderProps {
  borderRadius?: BorderRadius | Array<BorderRadius>;
  borderWidth?: BorderWidth | Array<BorderWidth>;
  borderColor?: BorderColor | Array<BorderColor>;
  borderOpacity?: BorderOpacity | Array<BorderOpacity>;
  borderStyle?: BorderStyle | Array<BorderStyle>;
  divideWidth?: DivideWidth | Array<DivideWidth>;
  divideColor?: DivideColor | Array<DivideColor>;
  divideOpacity?: DivideOpacity | Array<DivideOpacity>;
}

export interface TableProps {
  borderCollapse?: BorderCollapse | Array<BorderCollapse>;
  tableLayout?: TableLayout | Array<TableLayout>;
}

export interface EffectsProps {
  boxShadow?: BoxShadow | Array<BoxShadow>;
  opacity?: Opacity | Array<Opacity>;
}

export interface TransitionsProps {
  transitionProperty?: TransitionProperty | Array<TransitionProperty>;
  transitionDuration?: TransitionDuration | Array<TransitionDuration>;
  transitionTimingFunction?: TransitionTimingFunction | Array<TransitionTimingFunction>;
  transitionDelay?: TransitionDelay | Array<TransitionDelay>;
  animation?: Animation | Array<Animation>;
}

export interface TransformProps {
  transform?: Transform | Array<Transform>;
  scale?: Scale | Array<Scale>;
  rotate?: Rotate | Array<Rotate>;
  translate?: Translate | Array<Translate>;
  skew?: Skew | Array<Skew>;
  transformOrigin?: TransformOrigin | Array<TransformOrigin>;
}

export interface InteractivityProps {
  appearance?: Appearance | Array<Appearance>;
  cursor?: Cursor | Array<Cursor>;
  outline?: Outline | Array<Outline>;
  pointerEvents?: PointerEvents | Array<PointerEvents>;
  resize?: Resize | Array<Resize>;
  userSelect?: UserSelect | Array<UserSelect>;
}

export interface SvgProps {
  fill?: Fill | Array<Fill>;
  stroke?: Stroke | Array<Stroke>;
  strokeWidth?: StrokeWidth | Array<StrokeWidth>;
}

export interface PsuedoProps {
  group?: Group | Array<Group>;
}

export interface BackdropFilterProps {
  backdropFilter?: BackdropFilter | Array<BackdropFilter>;
}

export interface FilterProps {
  filter?: Filter | Array<Filter>;
}

export type {
  AlignContent,
  AlignItems,
  AlignSelf,
  Animation,
  Appearance,
  BackdropFilter,
  BackgroundAttachment,
  BackgroundClip,
  BackgroundColor,
  BackgroundImage,
  BackgroundOpacity,
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize,
  BorderCollapse,
  BorderColor,
  BorderOpacity,
  BorderRadius,
  BorderStyle,
  BorderWidth,
  BoxShadow,
  BoxSizing,
  Clear,
  Cursor,
  Display,
  DivideColor,
  DivideOpacity,
  DivideWidth,
  Fill,
  Filter,
  Flex,
  FlexDirection,
  FlexGrow,
  FlexShrink,
  FlexWrap,
  Float,
  FontFamily,
  FontSize,
  FontSmoothing,
  FontStyle,
  FontWeight,
  Gap,
  GradientColorStops,
  GridAutoFlow,
  GridColumn,
  GridColumnEnd,
  GridColumnStart,
  GridRow,
  GridRowEnd,
  GridRowStart,
  GridTemplateColumns,
  GridTemplateRows,
  Group,
  Height,
  Inset,
  JustifyContent,
  LetterSpacing,
  LineHeight,
  ListStylePosition,
  ListStyleType,
  Margin,
  MaxHeight,
  MaxWidth,
  MinHeight,
  MinWidth,
  ObjectFit,
  ObjectPosition,
  Offset,
  Opacity,
  Order,
  Outline,
  Overflow,
  Overscroll,
  Padding,
  PlaceholderColor,
  PlaceholderOpacity,
  PointerEvents,
  Position,
  Resize,
  Rotate,
  Scale,
  Skew,
  Space,
  Stroke,
  StrokeWidth,
  TableLayout,
  TextAlign,
  TextColor,
  TextDecoration,
  TextOpacity,
  TextTransform,
  Transform,
  TransformOrigin,
  TransitionDelay,
  TransitionDuration,
  TransitionProperty,
  TransitionTimingFunction,
  Translate,
  Truncate,
  UserSelect,
  VerticalAlign,
  Visibility,
  Whitespace,
  Width,
  WordBreak,
  ZIndex,
};

export interface AllProps
  extends LayoutProps,
    FlexboxProps,
    GridProps,
    SpacingProps,
    SizingProps,
    TextProps,
    BackgroundProps,
    BorderProps,
    TableProps,
    EffectsProps,
    TransitionsProps,
    TransformProps,
    InteractivityProps,
    SvgProps,
    PsuedoProps,
    BackdropFilterProps,
    FilterProps {}

export const layoutPropKeys = [
  'boxSizing',
  'display',
  'float',
  'clear',
  'objectFit',
  'objectPosition',
  'overflow',
  'overscroll',
  'position',
  'inset',
  'offset',
  'visibility',
  'zIndex',
];
export const flexboxPropKeys = [
  'flexDirection',
  'flexWrap',
  'alignItems',
  'alignContent',
  'alignSelf',
  'justifyContent',
  'flex',
  'flexGrow',
  'flexShrink',
  'order',
];
export const gridPropKeys = [
  'gridTemplateColumns',
  'gridColumn',
  'gridColumnStart',
  'gridColumnEnd',
  'gridTemplateRows',
  'gridRow',
  'gridRowStart',
  'gridRowEnd',
  'gap',
  'gridAutoFlow',
];
export const spacingPropKeys = ['margin', 'padding', 'space'];
export const sizingPropKeys = ['width', 'minWidth', 'maxWidth', 'height', 'minHeight', 'maxHeight'];
export const textPropKeys = [
  'fontFamily',
  'fontSize',
  'fontSmoothing',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'listStyleType',
  'listStylePosition',
  'placeholderColor',
  'placeholderOpacity',
  'textAlign',
  'textColor',
  'textOpacity',
  'textDecoration',
  'textTransform',
  'verticalAlign',
  'whitespace',
  'wordBreak',
  'truncate',
];
export const backgroundPropKeys = [
  'backgroundAttachment',
  'backgroundClip',
  'backgroundColor',
  'backgroundImage',
  'backgroundOpacity',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'gradientColorStops',
];
export const borderPropKeys = [
  'borderRadius',
  'borderWidth',
  'borderColor',
  'borderOpacity',
  'borderStyle',
  'divideWidth',
  'divideColor',
  'divideOpacity',
];
export const tablePropKeys = ['borderCollapse', 'tableLayout'];
export const effectsPropKeys = ['boxShadow', 'opacity'];
export const transitionsPropKeys = [
  'transitionProperty',
  'transitionDuration',
  'transitionTimingFunction',
  'transitionDelay',
  'animation',
];
export const transformPropKeys = ['transform', 'scale', 'rotate', 'translate', 'skew', 'transformOrigin'];
export const interactivityPropKeys = ['appearance', 'cursor', 'outline', 'pointerEvents', 'resize', 'userSelect'];
export const svgPropKeys = ['fill', 'stroke', 'strokeWidth'];
export const psuedoPropKeys = ['group'];
export const backdropFilterPropKeys = ['backdropFilter'];
export const filterPropKeys = ['filter'];

export const allPropKeys = [
  ...layoutPropKeys,
  ...flexboxPropKeys,
  ...gridPropKeys,
  ...spacingPropKeys,
  ...sizingPropKeys,
  ...textPropKeys,
  ...backgroundPropKeys,
  ...borderPropKeys,
  ...tablePropKeys,
  ...effectsPropKeys,
  ...transitionsPropKeys,
  ...transformPropKeys,
  ...interactivityPropKeys,
  ...svgPropKeys,
  ...psuedoPropKeys,
  ...backdropFilterPropKeys,
  ...filterPropKeys,
];
