/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
import React from 'react';
import { callAllHandlers } from 'utils';

import { useControllableProp } from './use-controllable';
import { useId } from './use-id';
import { usePrevious } from './use-previous';

export interface UseDisclosureProps {
  open?: boolean;
  defaultOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
  id?: string;
}

export function useDisclosure(props: UseDisclosureProps = {}) {
  const { onClose: onCloseProp, onOpen: onOpenProp, open: isOpenProp, id: idProp } = props;
  const [isOpenState, setIsOpen] = React.useState(props.defaultOpen || false);
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState);
  const prevIsOpen = usePrevious(isOpen);
  const id = useId(idProp, 'disclosure');

  const onClose = React.useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    onCloseProp?.();
  }, [isControlled, onCloseProp]);

  const onOpen = React.useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    onOpenProp?.();
  }, [isControlled, onOpenProp]);

  const onToggle = React.useCallback(() => {
    const action = isOpen ? onClose : onOpen;
    action();
  }, [isOpen, onOpen, onClose]);

  return {
    open: !!isOpen,
    prevIsOpen: !!prevIsOpen,
    onOpen,
    onClose,
    onToggle,
    isControlled,
    getButtonProps: (buttonProps: any = {}) => ({
      ...buttonProps,
      'aria-expanded': 'true',
      'aria-controls': id,
      onClick: callAllHandlers(buttonProps.onClick, onToggle),
    }),
    getDisclosureProps: (disclosureProps: any = {}) => ({
      ...disclosureProps,
      hidden: !isOpen,
      id,
    }),
  };
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
