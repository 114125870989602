import { BoxProps } from '../Box';
import { BadgeAppearance, BadgeVariant } from './types';

export default function useBadgeStyles(appearance: BadgeAppearance, variant: BadgeVariant): BoxProps {
  const props: BoxProps = {
    display: 'inline-flex',
    alignItems: 'items-center',
    padding: 'p-1',
    borderRadius: 'rounded',
    lineHeight: 'leading-none',
    fontWeight: 'font-semibold',
    fontSize: 'text-xs',
    borderWidth: 'border',
    textTransform: 'uppercase',
  };

  switch (appearance) {
    case 'default':
      props.textColor = variant === 'solid' ? 'text-gray-50' : 'text-gray-500';
      props.backgroundColor = variant === 'solid' ? 'bg-gray-500' : 'bg-gray-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-gray-200';
      break;

    case 'primary':
      props.textColor = variant === 'solid' ? 'text-blue-50' : 'text-blue-500';
      props.backgroundColor = variant === 'solid' ? 'bg-blue-500' : 'bg-blue-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-blue-200';
      break;

    case 'success':
      props.textColor = variant === 'solid' ? 'text-green-50' : 'text-green-500';
      props.backgroundColor = variant === 'solid' ? 'bg-green-500' : 'bg-green-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-green-200';
      break;

    case 'warning':
      props.textColor = variant === 'solid' ? 'text-orange-50' : 'text-orange-500';
      props.backgroundColor = variant === 'solid' ? 'bg-orange-500' : 'bg-orange-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-orange-200';
      break;

    case 'danger':
      props.textColor = variant === 'solid' ? 'text-red-50' : 'text-red-500';
      props.backgroundColor = variant === 'solid' ? 'bg-red-500' : 'bg-red-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-red-200';
      break;

    case 'note':
      props.textColor = variant === 'solid' ? 'text-yellow-50' : 'text-yellow-500';
      props.backgroundColor = variant === 'solid' ? 'bg-yellow-500' : 'bg-yellow-100';
      props.borderColor = variant === 'solid' ? 'border-transparent' : 'border-yellow-200';
      break;

    default:
      break;
  }

  return props;
}
