import Button, { ButtonProps } from 'components/Button';
import Icon from 'components/Icon';
import React from 'react';
import { __DEV__ } from 'utils';

import { IconButtonProps } from './types';

const IconButton = React.forwardRef((props: IconButtonProps, ref?: React.Ref<HTMLElement>) => {
  const { icon, iconSize, label, rounded, iconRotate, ...rest } = props;
  const styleProps: ButtonProps = {};

  if (rounded) {
    // TODO: fix up padding for the rounded option
    styleProps.borderRadius = 'rounded-full';
  }

  return (
    <Button ref={ref} spacing="compact" aria-label={label} {...styleProps} {...rest}>
      &#8203;
      <Icon
        name={icon}
        size={iconSize}
        transform="transform"
        rotate={iconRotate}
        transitionProperty="transition-all"
        transitionDuration="duration-200"
        transitionTimingFunction="ease-in-out"
      />
    </Button>
  );
});

if (__DEV__) {
  IconButton.displayName = 'IconButton';
}

export default IconButton;
export type { IconButtonProps };
