import Icon, { IconProps } from 'components/Icon';
import Spinner from 'components/Spinner';
import React from 'react';
import { __DEV__, isEmpty, isString, mapProps } from 'utils';

import useButtonStyles from './styles';
import { ButtonAppearance, ButtonProps, ButtonSize, ButtonSpacing, propKeys } from './types';

const ButtonIcon = (props: IconProps) => (
  <Icon
    flexShrink="flex-shrink-0"
    transform="transform"
    transitionProperty="transition-all"
    transitionDuration="duration-200"
    transitionTimingFunction="ease-in-out"
    {...props}
  />
);

const Button = React.forwardRef((props: ButtonProps, ref?: React.Ref<HTMLElement>) => {
  const {
    as,
    position = 'relative',
    type = 'button',
    appearance = 'default',
    size = 'medium',
    spacing = 'default',
    href,
    target,
    external,
    iconBefore,
    iconBeforeSize,
    iconBeforeRotate,
    iconAfter,
    iconAfterSize,
    iconAfterRotate,
    iconSize,
    iconRotate,
    disabled,
    loading = false,
    loadingText,
    onClick,
    active = false,
    fullWidth = false,
    children,
    ...rest
  } = props;

  const styleProps = useButtonStyles({ active, appearance, disabled, fullWidth, loading, position, size, spacing });
  // Whether to use a button element
  const useButton = !isString(href) || isEmpty(href);
  const externalProps = !useButton && external ? { target: target || '_blank', rel: 'noopener noreferrer' } : null;

  if (loading && !loadingText) {
    styleProps.textOpacity = 'text-opacity-0';
  }

  const renderIcons = !loading || !loadingText;

  const content = (
    <>
      {loading && (
        <Spinner
          position={!loadingText ? 'absolute' : undefined}
          inset={!loadingText ? 'inset-auto' : undefined}
          margin={loadingText ? 'mr-2' : undefined}
          size={size === 'sm' ? 'sm' : 'md'}
          textColor={styleProps.textColor}
        />
      )}

      {iconBefore && renderIcons && (
        <ButtonIcon
          name={iconBefore}
          size={iconBeforeSize || iconSize}
          rotate={iconBeforeRotate || iconRotate}
          margin="mr-2"
        />
      )}

      {(loading && loadingText) || children}

      {iconAfter && renderIcons && (
        <ButtonIcon
          name={iconAfter}
          size={iconAfterSize || iconSize}
          rotate={iconAfterRotate || iconRotate}
          margin="ml-2"
        />
      )}
    </>
  );

  return React.createElement(
    as || useButton ? 'button' : 'a',
    {
      ref,
      type: useButton ? type : undefined,
      href,
      target: !useButton ? target : undefined,
      onClick: disabled && !useButton ? (e: MouseEvent) => e.preventDefault() : onClick,
      disabled,
      'aria-disabled': disabled,
      ...mapProps({ ...styleProps, ...props }, rest, propKeys),
      ...externalProps,
    },
    content,
  );
});

if (__DEV__) {
  Button.displayName = 'Button';
}

export default Button;
export { propKeys };
export type { ButtonAppearance, ButtonProps, ButtonSize, ButtonSpacing };
