import React from 'react';
import { __DEV__, mapProps } from 'utils';

import { BoxProps, propKeys } from './types';

const Box = React.forwardRef((props: BoxProps, ref?: React.Ref<HTMLElement | SVGSVGElement>) => {
  const { as = 'div', children, htmlHeight, htmlWidth, ...rest } = props;

  return React.createElement(
    as,
    {
      ref,
      height: htmlHeight,
      width: htmlWidth,
      ...mapProps(props, rest, propKeys),
    },
    children,
  );
});

if (__DEV__) {
  Box.displayName = 'Box';
}

export default Box;

export { propKeys };
export type { BoxProps };
