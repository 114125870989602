import { useId } from '@reach/auto-id';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import React from 'react';
import { __DEV__ } from 'utils';

import TextInput from '../TextInput';
import { SearchInputProps } from './types';

const SearchInput = React.forwardRef((props: SearchInputProps, ref?: React.Ref<HTMLInputElement>) => {
  const { id = `search-input-${useId()}`, label = 'Search', placeholder = label, loading = false, ...rest } = props;

  return (
    <Box position="relative" width="w-full">
      <TextInput
        ref={ref}
        id={id}
        borderRadius="rounded-full"
        padding="pl-10"
        label={label}
        placeholder={placeholder}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        type="search"
        enterKeyHint="search"
        inputMode="search"
        {...rest}
      />

      <Flex
        position="absolute"
        inset={['inset-y-0', 'left-0']}
        alignItems="items-center"
        padding="pl-4"
        pointerEvents="pointer-events-none"
      >
        <Icon name="search" color="text-gray-400" />
      </Flex>

      {loading && (
        <Flex
          position="absolute"
          inset={['inset-y-0', 'right-0']}
          alignItems="items-center"
          padding="pr-4"
          pointerEvents="pointer-events-none"
        >
          <Spinner color="text-gray-500" />
        </Flex>
      )}
    </Box>
  );
});

if (__DEV__) {
  SearchInput.displayName = 'SearchInput';
}

export default SearchInput;
export type { SearchInputProps };
