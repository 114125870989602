import classnames from 'classnames';
import Box, { BoxProps } from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

import { LabelProps } from './types';

const Label = React.forwardRef((props: LabelProps, ref?: React.Ref<HTMLLabelElement>) => {
  const { visuallyHidden = false, ...rest } = props;

  const styleProps: BoxProps = {
    display: 'inline-flex',
    alignItems: 'items-center',
    cursor: 'cursor-pointer',
  };

  const classNames = classnames({ 'sr-only': visuallyHidden });

  return <Box as="label" ref={ref} dangerouslySetClassName={classNames} {...styleProps} {...rest} />;
});

if (__DEV__) {
  Label.displayName = 'Label';
}

export default Label;
export type { LabelProps };
