import Box, { BoxProps } from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

import { iconList, icons, IconType } from './icons';
import { IconProps, iconSizes } from './types';

interface IconItem {
  html: string;
  info: Record<string, string>;
}

const Icon = React.forwardRef((props: IconProps, ref?: React.Ref<SVGSVGElement>) => {
  const {
    color,
    focusable = false,
    name,
    role = 'presentation',
    size,
    display = 'inline-block',
    fill = 'fill-current',
    ...rest
  } = props;

  let { height, width } = props;

  if (!iconList.includes(name)) {
    return null;
  }

  const { html, info }: IconItem = icons[name];
  const { viewBox } = info;
  const { width: htmlWidth, height: htmlHeight } = info;
  const styleProps: BoxProps = { display, fill };

  if (display === 'inline-block') {
    styleProps.verticalAlign = 'align-middle';
  }

  if (color) {
    styleProps.textColor = color;
  }

  if (size) {
    [width, height] = iconSizes[size];
  }

  return (
    <Box
      ref={ref}
      as="svg"
      htmlHeight={!height ? htmlHeight : undefined}
      htmlWidth={!width ? htmlWidth : undefined}
      height={height}
      width={width}
      viewBox={viewBox}
      // @ts-ignore
      focusable={focusable}
      role={role}
      dangerouslySetInnerHTML={{ __html: html }}
      {...styleProps}
      {...rest}
    />
  );
});

if (__DEV__) {
  Icon.displayName = 'Icon';
}

export default Icon;
export { iconList };
export type { IconProps, IconType };
