import { ButtonProps } from 'components/Button';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';
import * as React from 'react';
import { __DEV__, cleanChildren } from 'utils';

import { ButtonGroupProps } from './types';

const ButtonGroup = React.forwardRef((props: ButtonGroupProps, ref?: React.Ref<HTMLElement>) => {
  const {
    attached,
    fullWidth,
    inline = true,
    // @ts-ignore Union too complex
    space = inline ? 'space-x-4' : 'space-y-2',
    alignItems = inline ? 'items-center' : undefined,
    flexDirection = inline ? 'flex-row' : 'flex-col',
    children,
    ...rest
  } = props;
  const validChildren = cleanChildren(children);

  const getBorderRadius = (index: number): { borderRadius: ButtonProps['borderRadius'] } | undefined => {
    const isFirst = index === 0;
    const isLast = index === validChildren.length - 1;

    if (!attached || validChildren.length === 1) {
      return undefined;
    }

    if (isFirst) {
      return { borderRadius: inline ? 'rounded-l-md' : 'rounded-t-md' };
    }

    if (isLast) {
      return { borderRadius: inline ? 'rounded-r-md' : 'rounded-b-md' };
    }

    if (!isFirst && !isLast) {
      return { borderRadius: 'rounded-none' };
    }

    return undefined;
  };

  const clones = validChildren.map((child, index) => {
    const buttonProps: ButtonProps = {
      zIndex: 'focus:z-10',
      ...getBorderRadius(index),
    };

    if (fullWidth && typeof fullWidth === 'boolean') {
      buttonProps.fullWidth = fullWidth;
    }

    // If the child is a tooltip, then we need to modify the child button
    if (child.type === Tooltip) {
      const button = React.cloneElement(child.props.children, {
        ...child.props.children.props,
        ...buttonProps,
      });

      return React.cloneElement(child, {
        ...child.props,
        children: button,
      });
    }

    return React.cloneElement(child, { ...buttonProps, ...child.props });
  });

  const attachedSpacing = inline ? '-space-x-px' : '-space-y-px';

  return (
    <Flex
      ref={ref}
      display="inline-flex"
      flexDirection={flexDirection}
      alignItems={alignItems}
      space={attached ? attachedSpacing : space}
      {...rest}
    >
      {clones}
    </Flex>
  );
});

if (__DEV__) {
  ButtonGroup.displayName = 'ButtonGroup';
}

export default ButtonGroup;
export type { ButtonGroupProps };
