import { ButtonProps } from './types';

export default function useButtonStyles(props: ButtonProps): ButtonProps {
  const { active, appearance, disabled, fullWidth, position, size, spacing, loading } = props;

  if (!appearance || appearance === 'none') {
    return {};
  }

  const isLink = ['link', 'subtle-link'].includes(appearance);

  // Common style props
  const styleProps: ButtonProps = {
    display: fullWidth ? 'flex' : 'inline-flex',
    width: fullWidth ? 'w-full' : undefined,
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    borderWidth: 'border',
    transitionProperty: 'transition',
    transitionDuration: 'duration-150',
    transitionTimingFunction: 'ease-in-out',
    outline: 'focus:outline-none',
    cursor: 'cursor-pointer',
    textDecoration: 'no-underline',
    position,
  };

  if (isLink) {
    styleProps.textAlign = 'text-inherit';
  } else {
    styleProps.fontWeight = 'font-medium';
    styleProps.userSelect = 'select-none';
    styleProps.borderRadius = 'rounded-md';
  }

  // Map the sizing
  switch (size) {
    case 'xl':
    case 'x-large':
      if (!isLink) {
        styleProps.fontSize = 'text-xl';
      }

      if (spacing !== 'none') {
        styleProps.padding = [spacing === 'compact' ? 'px-8' : 'px-12', 'py-4'];
      }
      break;

    case 'lg':
    case 'large':
      if (!isLink) {
        styleProps.fontSize = 'text-lg';
      }

      if (spacing !== 'none') {
        styleProps.padding = [spacing === 'compact' ? 'px-6' : 'px-8', 'py-3'];
      }
      break;

    case 'sm':
    case 'small':
      if (!isLink) {
        styleProps.fontSize = 'text-sm';
      }

      if (spacing !== 'none') {
        styleProps.padding = [spacing === 'compact' ? 'px-2' : 'px-3', 'py-1'];
      }
      break;

    case 'xs':
    case 'x-small':
      if (!isLink) {
        styleProps.fontSize = 'text-xs';
        styleProps.lineHeight = 'leading-tight';
      }

      if (spacing !== 'none') {
        styleProps.padding = [spacing === 'compact' ? 'px-1.5' : 'px-2.5', 'py-1'];
      }
      break;

    default:
    case 'md':
    case 'medium':
      if (!isLink) {
        styleProps.fontSize = 'text-base';
      }

      if (spacing !== 'none') {
        styleProps.padding = [spacing === 'compact' ? 'px-3' : 'px-4', 'py-2'];
      }
      break;
  }

  // Disabled state
  if (disabled) {
    styleProps.textColor = 'text-gray-400';
    styleProps.cursor = 'cursor-not-allowed';

    switch (appearance) {
      case 'default':
      case 'primary':
      case 'secondary':
      case 'success':
      case 'warning':
      case 'danger':
        styleProps.borderColor = 'border-gray-200';
        styleProps.backgroundColor = 'bg-gray-100';
        break;

      default:
        styleProps.borderColor = 'border-transparent';
        break;
    }

    return styleProps;
  }

  switch (appearance) {
    case 'default':
      styleProps.textColor = 'text-gray-500';
      styleProps.backgroundColor = active ? 'bg-gray-100' : ['bg-white', 'hover:bg-gray-100', 'focus:bg-white'];
      styleProps.borderColor = ['border-gray-200', 'focus:border-gray-400'];
      styleProps.borderOpacity = ['focus:border-opacity-75'];
      styleProps.boxShadow = ['focus:shadow-outline-gray'];

      break;

    case 'primary':
      styleProps.textColor = 'text-blue-50';
      styleProps.backgroundColor = active ? 'bg-blue-600' : ['bg-blue-500', 'hover:bg-blue-600', 'focus:bg-blue-600'];
      styleProps.borderColor = ['border-transparent', 'focus:border-blue-700'];
      styleProps.boxShadow = ['focus:shadow-outline-blue'];
      break;

    case 'secondary':
      styleProps.textColor = 'text-gray-50';
      styleProps.backgroundColor = active ? 'bg-gray-600' : ['bg-gray-500', 'hover:bg-gray-600', 'focus:bg-gray-600'];
      styleProps.borderColor = ['border-transparent', 'focus:border-gray-700'];
      styleProps.boxShadow = ['focus:shadow-outline-gray'];
      break;

    case 'success':
      styleProps.textColor = 'text-green-50';
      styleProps.backgroundColor = active
        ? 'bg-green-600'
        : ['bg-green-500', 'hover:bg-green-600', 'focus:bg-green-600'];
      styleProps.borderColor = ['border-transparent', 'focus:border-green-700'];
      styleProps.boxShadow = ['focus:shadow-outline-green'];
      break;

    case 'warning':
      styleProps.textColor = 'text-orange-50';
      styleProps.backgroundColor = active
        ? 'bg-orange-600'
        : ['bg-orange-500', 'hover:bg-orange-600', 'focus:bg-orange-600'];
      styleProps.borderColor = ['border-transparent', 'focus:border-orange-700'];
      styleProps.boxShadow = ['focus:shadow-outline-orange'];
      break;

    case 'danger':
      styleProps.textColor = 'text-red-50';
      styleProps.backgroundColor = active ? 'bg-red-600' : ['bg-red-500', 'hover:bg-red-600', 'focus:bg-red-600'];
      styleProps.borderColor = ['border-transparent', 'focus:border-red-700'];
      styleProps.boxShadow = ['focus:shadow-outline-red'];
      break;

    case 'ghost':
      styleProps.textColor = [active ? 'text-gray-700' : 'text-gray-500'];

      if (!loading && !active) {
        styleProps.textColor.push('hover:text-gray-700', 'focus:text-gray-700');
      }

      styleProps.backgroundColor = active
        ? 'bg-gray-100'
        : ['bg-transparent', 'hover:bg-gray-100', 'focus:bg-gray-100'];
      styleProps.borderColor = ['border-transparent'];
      break;

    case 'link':
      styleProps.textColor = [active ? 'text-blue-700' : 'text-blue-500'];

      if (!loading && !active) {
        styleProps.textColor.push('hover:text-blue-700', 'focus:text-blue-700');
      }

      styleProps.textDecoration = ['no-underline', 'hover:underline', 'focus:underline'];
      styleProps.borderColor = ['border-transparent'];
      styleProps.boxShadow = ['focus:shadow-outline', 'focus:shadow-outline-blue'];
      break;

    case 'subtle-link':
      styleProps.textColor = [active ? 'text-gray-700' : 'text-gray-500'];

      if (!loading && !active) {
        styleProps.textColor.push('hover:text-gray-700', 'focus:text-gray-700');
      }

      styleProps.textDecoration = ['no-underline', 'hover:underline', 'focus:underline'];
      styleProps.borderColor = ['border-transparent'];
      styleProps.boxShadow = ['focus:shadow-outline', 'focus:shadow-outline-gray'];
      break;

    default:
      break;
  }

  return styleProps;
}
