import React from 'react';

import { CommonProps } from '../types';
import { IconType } from './icons';

const iconSizes: Record<string, [CommonProps['width'], CommonProps['height']]> = {
  sm: ['w-3', 'h-3'],
  md: ['w-4', 'h-4'],
  lg: ['w-6', 'h-6'],
  xl: ['w-8', 'h-8'],
  '2xl': ['w-12', 'h-12'],
  '3xl': ['w-16', 'h-16'],
};

type HtmlAttributes = Omit<React.SVGAttributes<SVGSVGElement>, keyof Props>;

interface Props extends Omit<CommonProps, 'size' | 'color'> {
  /** Which icon to display */
  name: IconType;
  /** The role of the icon. `presentation` or `img` */
  role?: 'presentation' | 'img';
  /** If `false`, it means the icon is used within interactive element and won't be focuable */
  focusable?: boolean;
  /** Which color to fill the icon with */
  color?: CommonProps['textColor'];
  /** Override the default size of the icon. This is often not recommended due to rendering issues. By default it will render at 100%. */
  size?: keyof typeof iconSizes;
}

export interface IconProps extends Props, HtmlAttributes {}
export { iconSizes };
