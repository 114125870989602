import React from 'react';
import { __DEV__, mapProps } from 'utils';

import { LogomarkProps, logomarkSizes, propKeys } from './types';

const Logomark = React.forwardRef((props: LogomarkProps, ref?: React.Ref<SVGSVGElement>) => {
  const { size, animated = false, flat = false, role = 'presentation', ...rest } = props;
  let width = 64;
  let height = 64;

  if (size) {
    width = logomarkSizes[size];
    height = width;
  }

  return (
    <svg
      ref={ref}
      role={role}
      width={width}
      height={height}
      viewBox="0 0 276.75 276.75"
      {...mapProps(props, rest, propKeys)}
    >
      <path
        className={animated ? 'animate-new-logomark-0' : undefined}
        fill={flat ? 'currentColor' : '#ff3e00'}
        d="M148.4,232.07a11.7,11.7,0,1,0,16.54,0A11.69,11.69,0,0,0,148.4,232.07Zm30.23-30.23a11.69,11.69,0,1,0,16.54,0A11.7,11.7,0,0,0,178.63,201.84Zm75.23-75.23L208.3,172.17a11.7,11.7,0,0,0,1.29,17.66c4.78,3.6,11.66,2.48,15.89-1.75l13.33-13.33A54.32,54.32,0,0,0,253.86,126.61Z"
      />
      <path
        className={animated ? 'animate-new-logomark-4' : undefined}
        fill={flat ? 'currentColor' : '#ff5200'}
        d="M103.35,218a11.7,11.7,0,1,0,16.54,0A11.7,11.7,0,0,0,103.35,218Zm30.23-30.23a11.7,11.7,0,1,0,16.54,0A11.69,11.69,0,0,0,133.58,187.76Zm105.23-90a54.47,54.47,0,0,0-9.18-7.35l-66.34,66.34a12.59,12.59,0,0,0,0,17.8h0a12.59,12.59,0,0,0,17.8,0l66-66A54.33,54.33,0,0,0,238.81,97.79Z"
      />
      <path
        className={animated ? 'animate-new-logomark-3' : undefined}
        fill={flat ? 'currentColor' : '#ff6500'}
        d="M161.87,97.8L65.3,194.36a54.33,54.33,0,0,0,50-14.66l96.57-96.57A54.32,54.32,0,0,0,161.87,97.8Z"
      />
      <path
        className={animated ? 'animate-new-logomark-2' : undefined}
        fill={flat ? 'currentColor' : '#ff7900'}
        d="M127,73.2a11.7,11.7,0,1,0,16.54,0A11.69,11.69,0,0,0,127,73.2ZM173.81,43a11.7,11.7,0,1,0,0,16.54A11.69,11.69,0,0,0,173.81,43ZM96.07,102.91l-66,66a54.32,54.32,0,0,0,8.29,10.77h0a54.5,54.5,0,0,0,9.18,7.35l66.34-66.34A12.59,12.59,0,0,0,96.07,102.91Z"
      />
      <path
        className={animated ? 'animate-new-logomark-1' : undefined}
        fill={flat ? 'currentColor' : '#ff8c00'}
        d="M82,59.11a11.69,11.69,0,1,0,16.54,0A11.7,11.7,0,0,0,82,59.11Zm46.77-30.23a11.7,11.7,0,1,0,0,16.54A11.69,11.69,0,0,0,128.76,28.89ZM52.32,88.78l-14,14a54.32,54.32,0,0,0-15,48.13l45.55-45.55A11.7,11.7,0,0,0,52.32,88.78Z"
      />
    </svg>
  );
});

if (__DEV__) {
  Logomark.displayName = 'Logomark';
}

export default Logomark;
export type { LogomarkProps };
