/* eslint-disable no-shadow */
import { BoxProps } from 'components/Box';
import { Placement, usePopper, UsePopperProps } from 'components/Popper';
import { useDisclosure, useEventListener, useId } from 'hooks';
import { Ref, useCallback, useEffect, useRef } from 'react';
import { callAllHandlers, Dict, mergeRefs } from 'utils';

export interface UseTooltipProps {
  openDelay?: number;
  closeDelay?: number;
  closeOnClick?: boolean;
  closeOnMouseDown?: boolean;
  onOpen?(): void;
  onClose?(): void;
  placement?: Placement;
  id?: string;
  open?: boolean;
  defaultOpen?: boolean;
  /** The size of the arrow in css units (numeric) @default 8 ( = 8px ) */
  arrowSize?: UsePopperProps['arrowSize'];
  modifiers?: UsePopperProps['modifiers'];
  /** Keep the tooltip shown while mouse enter it. */
  hoverable?: boolean;
  hoverDelay?: number;
  disabled?: boolean;
}

export function useTooltip(props: UseTooltipProps = {}) {
  const {
    openDelay = 0,
    closeDelay = 0,
    closeOnClick = true,
    closeOnMouseDown,
    onOpen,
    onClose,
    placement,
    id,
    open: openProp,
    defaultOpen,
    arrowSize = 10,
    modifiers,
    hoverable,
    hoverDelay = 200,
    disabled,
  } = props;
  const { open, onOpen: onOpenProp, onClose: onCloseProp } = useDisclosure({
    open: openProp,
    defaultOpen,
    onOpen,
    onClose,
  });

  const popper = usePopper({
    forceUpdate: open,
    placement,
    arrowSize,
    modifiers,
  });

  const tooltipId = useId(id, 'tooltip');
  const ref = useRef<HTMLElement>(null);
  const triggerRef = mergeRefs(ref, popper.reference.ref);
  const enterTimeout = useRef<number>();
  const exitTimeout = useRef<number>();
  const delayClose = useRef(false);
  const innerCloseDelay = closeDelay < hoverDelay && hoverable ? hoverDelay : closeDelay;

  const openWithDelay = useCallback(() => {
    if (!disabled) {
      enterTimeout.current = window.setTimeout(onOpenProp, openDelay);
    }
  }, [disabled, onOpenProp, openDelay]);

  const closeWithDelay = useCallback(() => {
    if (enterTimeout.current) {
      clearTimeout(enterTimeout.current);
    }
    exitTimeout.current = window.setTimeout(() => {
      if (!delayClose.current) {
        onCloseProp();
      }
    }, innerCloseDelay);
  }, [innerCloseDelay, onCloseProp]);

  const onClick = useCallback(() => {
    if (closeOnClick) {
      closeWithDelay();
    }
  }, [closeOnClick, closeWithDelay]);

  const onMouseDown = useCallback(() => {
    if (closeOnMouseDown) {
      closeWithDelay();
    }
  }, [closeOnMouseDown, closeWithDelay]);

  const onKeyDown = (event: KeyboardEvent) => {
    if (open && event.key === 'Escape') {
      closeWithDelay();
    }
  };

  const onTooltipMouseEnter = useCallback(() => {
    if (hoverable) {
      delayClose.current = true;
    }
  }, [hoverable, openWithDelay]);

  const onTooltipMouseLeave = useCallback(() => {
    if (hoverable) {
      delayClose.current = false;
      closeWithDelay();
    }
  }, [hoverable, closeWithDelay]);

  useEventListener('keydown', onKeyDown);

  useEffect(
    () => () => {
      clearTimeout(enterTimeout.current);
      clearTimeout(exitTimeout.current);
    },
    [],
  );

  const getTriggerProps = useCallback(
    (triggerProps: Dict = {}, externalTriggerRef: Ref<HTMLElement> = null) => ({
      ...triggerProps,
      ref: mergeRefs(externalTriggerRef, triggerRef),
      onMouseLeave: callAllHandlers(triggerProps.onMouseLeave, closeWithDelay),
      onMouseEnter: callAllHandlers(triggerProps.onMouseEnter, openWithDelay),
      onClick: callAllHandlers(triggerProps.onClick, onClick),
      onMouseDown: callAllHandlers(triggerProps.onMouseDown, onMouseDown),
      onFocus: callAllHandlers(triggerProps.onFocus, openWithDelay),
      onBlur: callAllHandlers(triggerProps.onBlur, closeWithDelay),
      'aria-describedby': open ? tooltipId : undefined,
    }),
    [closeWithDelay, open, onClick, onMouseDown, openWithDelay, tooltipId, triggerRef],
  );

  const getTooltipProps = useCallback(
    (tooltipProps: Dict = {}, tooltipRef: Ref<HTMLElement> = null) => ({
      ...({
        maxWidth: 'max-w-xs',
        padding: ['px-3', 'py-2'],
        backgroundColor: 'bg-gray-900',
        borderRadius: 'rounded-md',
        textColor: 'text-white',
        fontSize: 'text-sm',
        boxShadow: 'shadow-md',
        zIndex: 'z-50',
      } as BoxProps),
      ...tooltipProps,
      onMouseEnter: callAllHandlers(tooltipProps.onMouseEnter, onTooltipMouseEnter),
      onMouseLeave: callAllHandlers(tooltipProps.onMouseLeave, onTooltipMouseLeave),
      id: tooltipId,
      role: 'tooltip',
      ref: mergeRefs(tooltipRef, popper.popper.ref),
      style: {
        ...tooltipProps.style,
        ...popper.popper.style,
      },
    }),
    [popper.popper.ref, popper.popper.style, tooltipId],
  );

  const getArrowProps = useCallback(
    (arrowProps: Dict = {}, arrowRef: Ref<HTMLElement> = null) => ({
      ...({
        backgroundColor: 'bg-inherit',
      } as BoxProps),
      ...arrowProps,
      ref: mergeRefs(arrowRef, popper.arrow.ref),
      style: { ...arrowProps.style, ...popper.arrow.style },
    }),
    [popper.arrow.ref, popper.arrow.style],
  );

  return {
    open,
    show: openWithDelay,
    hide: closeWithDelay,
    placement: popper.placement,
    getTriggerProps,
    getTooltipProps,
    getArrowProps,
  };
}

export type UseTooltipReturn = ReturnType<typeof useTooltip>;
