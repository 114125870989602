import Box from 'components/Box';
import Icon from 'components/Icon';
import React from 'react';
import { __DEV__ } from 'utils';

import useBadgeStyles from './styles';
import { BadgeProps } from './types';

const Badge = React.forwardRef((props: BadgeProps, ref?: React.Ref<HTMLElement>) => {
  const { children, appearance = 'default', variant = 'subtle', iconBefore, iconAfter, ...rest } = props;
  const styleProps = useBadgeStyles(appearance, variant);

  return (
    <Box as="small" ref={ref} {...styleProps} {...rest}>
      {iconBefore && <Icon name={iconBefore} margin="mr-1" />}
      {children}
      {iconAfter && <Icon name={iconAfter} margin="ml-1" />}
    </Box>
  );
});

if (__DEV__) {
  Badge.displayName = 'Badge';
}

export default Badge;
export type { BadgeProps };
