import Badge from 'components/Badge';
import Box from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

import useTextStyles from './styles';
import { TextProps } from './types';

const Text = React.forwardRef((props: TextProps, ref?: React.Ref<HTMLElement>) => {
  const { as = 'p', truncate, ...rest } = props;

  if (as === 'kbd') {
    return (
      <Badge
        as="kbd"
        ref={ref}
        padding="p-1"
        fontFamily="font-sans"
        boxShadow="shadow-sm"
        borderWidth={['border', 'border-b-2']}
        borderColor="border-gray-300"
        {...rest}
      />
    );
  }

  return <Box ref={ref} as={as} {...useTextStyles(as, truncate)} {...rest} />;
});

if (__DEV__) {
  Text.displayName = 'Text';
}

export default Text;
export type { TextProps };
