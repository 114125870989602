import { BoxProps } from 'components/Box';
import { mapTruncateValue } from 'utils';

import { TextElements } from './types';

export default function useTextStyles(as: TextElements, truncate: boolean | 2 | 3 | 4 | 5 = false): BoxProps {
  const props: BoxProps = {
    truncate: mapTruncateValue(truncate),
  };

  switch (as) {
    case 'small':
      props.fontSize = 'text-sm';
      break;

    case 'pre':
    case 'code':
      props.fontFamily = 'font-mono';
      props.fontSize = 'text-code-inline';
      props.fontWeight = 'font-normal';
      break;

    case 'mark':
      props.backgroundColor = 'bg-yellow-100';
      props.textColor = 'text-gray-800';
      props.padding = 'px-1';
      break;

    default:
      break;
  }

  if (as === 'code') {
    props.textColor = 'text-red-500';
  }

  return props;
}
