/* eslint-disable no-param-reassign */
import * as React from 'react';

import { isFunction } from './assertion';
import { As } from './types';

export type PropsWithAs<Props = any, Type extends As = As> = Props &
  Omit<React.ComponentProps<Type>, 'as' | keyof Props> & {
    as?: Type;
  };

export interface ComponentWithAs<Props, DefaultType extends As> {
  <Type extends As>(props: PropsWithAs<Props, Type> & { as: Type }): JSX.Element;
  (props: PropsWithAs<Props, DefaultType>): JSX.Element;
}

type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>;

export function setRef<T = any>(ref: React.Ref<T> = null, value: T): void {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(value);
  } else {
    // eslint-disable-next-line no-param-reassign
    (ref as React.MutableRefObject<T>).current = value;
  }
}

/**
 * Assigns a value to a ref function or object
 *
 * @param ref the ref to assign to
 * @param value the value
 */
export function assignRef<T = any>(ref: ReactRef<T> | undefined, value: T) {
  if (ref == null) return;

  if (isFunction(ref)) {
    ref(value);
    return;
  }

  try {
    // @ts-ignore
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign to value to
 */
export function mergeRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  return (node: T | null) => {
    refs.forEach((ref) => assignRef(ref, node));
  };
}

export function forwardRefWithAs<Props, DefaultType extends As>(
  component: React.ForwardRefRenderFunction<any, any>,
): ComponentWithAs<Props, DefaultType> {
  return (React.forwardRef(component) as unknown) as ComponentWithAs<Props, DefaultType>;
}

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function cleanChildren(children: React.ReactChildren | React.ReactNode): React.ReactElement[] {
  return React.Children.toArray(children).filter((child) => React.isValidElement(child)) as React.ReactElement[];
}

/**
 * Get a list of all children matching the specified type(s)
 * @param children
 * @param type
 */
export function filterChildren(
  children: React.ReactChildren | React.ReactNode,
  type: any | any[],
): Array<React.ReactElement> {
  return cleanChildren(children).filter((child) =>
    Array.isArray(type) ? type.includes(child.type) : child.type === type,
  );
}

/**
 * Get a list of children, including those within Fragments
 * @param children
 */
export function flattenChildren(children: React.ReactChildren | React.ReactNode): Array<React.ReactElement> {
  return cleanChildren(children)
    .map((child) => (child.type === React.Fragment ? child.props.children : child))
    .flat();
}
