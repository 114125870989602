import { useId } from '@reach/auto-id';
import Box from 'components/Box';
import { useInputStyle, UseInputStyleProps } from 'hooks';
import React from 'react';
import { __DEV__ } from 'utils';

import Label from '../Label';
import { TextInputProps } from './types';

const TextInput = React.forwardRef((props: TextInputProps, ref?: React.Ref<HTMLInputElement>) => {
  const {
    id = `text-input-${useId()}`,
    type = 'text',
    label,
    placeholder = label,
    invalid,
    enterKeyHint,
    ...rest
  } = props;

  const styleProps = useInputStyle({ block: true, type: 'text', ...props } as UseInputStyleProps);

  return (
    <>
      {label && (
        <Label visuallyHidden htmlFor={id}>
          {label}
        </Label>
      )}

      <Box
        as="input"
        ref={ref}
        type={type}
        id={id}
        dir="auto"
        placeholder={placeholder}
        // @ts-ignore TypeScript is a little behind the times 😉
        enterKeyHint={enterKeyHint}
        aria-invalid={invalid}
        dangerouslySetClassName="form-input"
        {...styleProps}
        {...rest}
      />
    </>
  );
});

if (__DEV__) {
  TextInput.displayName = 'TextInput';
}

export default TextInput;
export type { TextInputProps };
