import Box, { BoxProps } from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

const VisuallyHidden = React.forwardRef((props: BoxProps, ref?: React.Ref<HTMLElement>) => (
  <Box ref={ref} dangerouslySetClassName="sr-only" {...props} />
));

if (__DEV__) {
  VisuallyHidden.displayName = 'VisuallyHidden';
}

export default VisuallyHidden;
