import { isFunction } from './assertion';
import { FunctionArguments } from './types';

export const noop = () => {};

export function createChainedFunction<T>(...funcs: T[]): T {
  return funcs.reduce((acc: any, func: any) => {
    if (func == null) {
      return acc;
    }

    return function chainedFunction(...args: any[]) {
      acc.apply(this, args);
      func.apply(this, args);
    };
  }, noop);
}

// Used to compose event handlers.
export function callAllEventHandlers(...fns: ((...args: any[]) => any | undefined)[]) {
  return (...args: any[]) =>
    fns.some((fn) => {
      if (fn) {
        return fn(...args);
      }
      return false;
    });
}

export function callAllHandlers<T extends (event: any) => void>(...fns: (T | undefined)[]) {
  return (event: FunctionArguments<T>[0]) => {
    fns.some((fn) => {
      if (fn) {
        fn(event);
      }
      return event && event.defaultPrevented;
    });
  };
}

export const wrapEvent = (externalHandler?: (event: any) => void, internalHandler?: (event: any) => void) => (
  event: any,
) => {
  if (externalHandler) {
    externalHandler(event);
  }

  if (!event.defaultPrevented && internalHandler) {
    return internalHandler(event);
  }

  return undefined;
};

export function runIfFn<T, U>(valueOrFn: T | ((...arg: U[]) => T), ...args: U[]): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
