import { allPropKeys, AllProps } from '../types';

const additionalPropKeys = ['dangerouslySetClassName'];
const commonPropKeys = [...allPropKeys, ...additionalPropKeys];

export type BlockedProps = 'className';

interface AdditionalProps {
  /** If you *must* set the className */
  dangerouslySetClassName?: string;
}

export interface CommonProps extends AdditionalProps, AllProps {}

export { commonPropKeys };
export * from '../types';
