import React from 'react';

import { BlockedProps, CommonProps } from '../types';

export { commonPropKeys as propKeys } from '../types';

type HtmlAttributes = Omit<Omit<React.SVGAttributes<SVGSVGElement>, keyof Props>, BlockedProps>;

const logomarkSizes = {
  xs: 16,
  sm: 32,
  md: 48,
  lg: 64,
  xl: 96,
  '2xl': 128,
  '3xl': 256,
};

interface Props extends CommonProps {
  /** The size of the logo */
  size?: keyof typeof logomarkSizes;
  /** Whether to animate it */
  animated?: boolean;
  /** Whether to fill with currentColor to allow changing color */
  flat?: boolean;
  /** The role of the icon. `presentation` or `img` */
  role?: 'presentation' | 'img';
}

export interface LogomarkProps extends Props, HtmlAttributes {}
export { logomarkSizes };
