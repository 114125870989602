import { BoxProps } from '../components';

export interface UseInputStyleProps {
  disabled?: boolean;
  invalid?: boolean;
  readOnly?: boolean;
  indeterminate?: boolean;
  block?: boolean;
  type: 'text' | 'select' | 'radio' | 'checkbox';
  size?: 'sm' | 'md';
}

export function useInputStyle(props: UseInputStyleProps) {
  const { size, block, disabled, indeterminate, invalid, readOnly, type } = props;

  const styleProps: BoxProps = {
    textColor: 'text-gray-600',
    borderColor: 'border-gray-200',
    boxShadow: 'focus:shadow-outline-blue',
  };

  if (block) {
    styleProps.display = 'block';
    styleProps.width = 'w-full';
  }

  if (disabled || readOnly) {
    styleProps.cursor = 'cursor-not-allowed';
  }

  if (disabled) {
    styleProps.cursor = 'cursor-not-allowed';
    styleProps.textColor = 'text-gray-400';
    styleProps.backgroundColor = 'bg-gray-100';
    // Reset focus styles
    styleProps.borderColor = ['border-gray-200', 'focus:border-gray-200'];
    styleProps.boxShadow = 'focus:shadow-none';

    if (['radio', 'checkbox'].includes(type)) {
      styleProps.backgroundColor = [styleProps.backgroundColor, 'checked:bg-gray-400'];
      styleProps.borderColor = [...styleProps.borderColor, 'checked:border-gray-400'];
    }
  }

  if (invalid) {
    styleProps.borderColor = ['border-red-500', 'focus:border-red-500'];
    styleProps.boxShadow = 'focus:shadow-outline-red';

    if (['radio', 'checkbox'].includes(type)) {
      styleProps.backgroundColor = ['bg-red-100', 'checked:bg-red-500'];
    }
  }

  if (['radio', 'checkbox'].includes(type)) {
    styleProps.height = 'h-4';
    styleProps.width = 'w-4';
    styleProps.textColor = 'text-blue-500';
  } else {
    styleProps.transitionProperty = 'transition-shadow';
    styleProps.transitionDuration = 'duration-200';
    styleProps.transitionTimingFunction = 'ease-in-out';
  }

  if (type === 'checkbox' && indeterminate) {
    styleProps.backgroundColor = 'bg-blue-500';
    styleProps.borderColor = 'border-transparent';
  }

  switch (size) {
    case 'sm':
      styleProps.padding = 'py-1';
      styleProps.fontSize = 'text-sm';
      break;
    case 'md':
    default:
      break;
  }

  return styleProps;
}
