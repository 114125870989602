import Box from 'components/Box';
import React from 'react';
import { __DEV__ } from 'utils';

import { FlexProps } from './types';

const Flex = React.forwardRef((props: FlexProps, ref?: React.Ref<HTMLElement>) => {
  const { display = 'flex', ...rest } = props;

  return <Box ref={ref} display={display} {...rest} />;
});

if (__DEV__) {
  Flex.displayName = 'Flex';
}

export default Flex;
export type { FlexProps };
