/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from 'classnames';

import { Truncate } from '../types/Truncate';
import { isNumber } from './assertion';
import { filterObject } from './object';

/**
 * Get classnames from props values in a whitelisted set of keys
 */
export function mapClassNames(props: Record<string, any>, keys: Array<string>): Array<string> {
  return keys
    ?.map((k: string) => props[k])
    .filter(Boolean)
    .flat();
}

/**
 * Map props and classnames
 * @param props
 * @param rest
 * @param keys
 */
export function mapProps(
  props: Record<string, any>,
  rest: Record<string, any>,
  keys: Array<string>,
  classNames: (string | Record<string, boolean | undefined> | undefined)[] = [],
): React.Attributes & Record<string, any> {
  const className = classnames(...classNames, ...mapClassNames(props, keys)) || undefined;

  return {
    className,
    ...filterObject(rest, keys, true),
  };
}

export function mapTruncateValue(truncate: boolean | 2 | 3 | 4 | 5): Truncate | undefined {
  if (truncate === true) {
    return 'truncate';
  }

  if (isNumber(truncate)) {
    switch (truncate) {
      case 2:
        return 'truncate-2-lines';

      case 3:
        return 'truncate-3-lines';

      case 4:
        return 'truncate-4-lines';

      case 5:
        return 'truncate-5-lines';

      default:
        return undefined;
    }
  }

  return undefined;
}
