import { useSafeLayoutEffect } from 'hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { __DEV__, createContext, isBrowser } from 'utils';

import { usePortalManager } from './portal-manager';
import { PortalProps } from './types';

type PortalContext = HTMLDivElement | null;

const [PortalContextProvider, usePortalContext] = createContext<PortalContext>({
  strict: false,
  name: 'PortalContext',
});

const Portal = (props: PortalProps) => {
  const { onMount, onUnmount, children, getContainer } = props;

  const [portal] = React.useState(() => {
    if (isBrowser) {
      const div = document.createElement('div');
      div.className = 'sajari-portal';
      return div;
    }
    // for ssr
    return null;
  });

  // This portal might be nested in another portal.
  // Let's read from the portal context to check this.
  const parentPortal = usePortalContext();
  // If there's a PortalManager rendered, let's read from it.
  // We use the portal manager to manage multiple portals
  const manager = usePortalManager();

  const append = React.useCallback(
    (container: HTMLElement | null) => {
      // if user specified a mount node, do nothing.
      if (!portal || !container) return;

      // else, simply append component to the portal node
      container.appendChild(portal);
    },
    [portal],
  );

  useSafeLayoutEffect(() => {
    // get the custom container from the container prop
    const customContainer = getContainer?.();
    // We need to know where to mount this portal, we have 4 options:
    // - If a mountRef is specified, we'll use that as the container
    // - If portal is nested, use the parent portal node as container.
    // - If it's not nested, use the manager's node as container
    // - else use document.body as containers
    const container = customContainer ?? parentPortal ?? manager?.node ?? document.body;

    // Append portal node to the computed container
    append(container);

    onMount?.();

    return () => {
      onUnmount?.();

      if (!portal) return;

      if (container?.contains(portal)) {
        container?.removeChild(portal);
      }
    };
  }, [getContainer, portal, parentPortal, onMount, onUnmount, manager?.node, append]);

  const innerChildren = manager?.zIndex ? (
    <div
      className="sajari-portal-zIndex"
      style={{
        position: 'absolute',
        zIndex: manager.zIndex,
        width: '100%',
      }}
    >
      {children}
    </div>
  ) : (
    children
  );

  if (!portal) {
    return <React.Fragment>{innerChildren}</React.Fragment>;
  }

  return ReactDOM.createPortal(<PortalContextProvider value={portal}>{innerChildren}</PortalContextProvider>, portal);
};

if (__DEV__) {
  Portal.displayName = 'Portal';
}

export type { PortalProps };
export default Portal;
