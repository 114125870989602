export const icons = {
  account: {
    html:
      '<circle cx="8" cy="7" r="3"></circle><path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 12.4c-.6-.7-1.4-1.4-3-1.4H7c-1.6 0-2.4.7-3 1.4C2.8 11.3 2 9.8 2 8c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'add-circle': {
    html:
      '<g><path d="M9 4H7v3H4v2h3v3h2V9h3V7H9z"></path><path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"></path></g>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  add: {
    html:
      '<path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'ai-chip': {
    html:
      '<g><path d="M38 6H10a4 4 0 00-4 4v28a4 4 0 004 4h28a4 4 0 004-4V10a4 4 0 00-4-4zm-1 30a1 1 0 01-1 1H12a1 1 0 01-1-1V12a1 1 0 011-1h24a1 1 0 011 1z"></path><path d="M13 13v22h22V13zm11.971 16.9l-1.284-3.568h-4.913L17.518 29.9H15.3L20.11 17h2.29l4.8 12.9zm6.029 0h-2V17.053h2z"></path><path d="M21.564 19.918q-.233-.756-.32-1.107a19.845 19.845 0 01-.694 2.364l-1.16 3.348h3.744l-1.2-3.5q-.134-.349-.37-1.105zM1 15h3v2H1a1 1 0 01-1-1 1 1 0 011-1zM1 23h3v2H1a1 1 0 01-1-1 1 1 0 011-1zM1 31h3v2H1a1 1 0 01-1-1 1 1 0 011-1zM47 17h-3v-2h3a1 1 0 011 1 1 1 0 01-1 1zM47 25h-3v-2h3a1 1 0 011 1 1 1 0 01-1 1zM47 33h-3v-2h3a1 1 0 011 1 1 1 0 01-1 1zM16 0a1 1 0 011 1v3h-2V1a1 1 0 011-1zM32 0a1 1 0 011 1v3h-2V1a1 1 0 011-1zM24 0a1 1 0 011 1v3h-2V1a1 1 0 011-1zM15 44h2v3a1 1 0 01-1 1 1 1 0 01-1-1v-3zM31 44h2v3a1 1 0 01-1 1 1 1 0 01-1-1v-3zM23 44h2v3a1 1 0 01-1 1 1 1 0 01-1-1v-3z"></path></g>',
    info: { viewBox: '0 0 48 48', width: '16', height: '16' },
  },
  analytics: {
    html: '<path d="M12 1h4v14h-4zM6 5h4v10H6zM0 9h4v6H0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'arrow-down': {
    html: '<path d="M14.7 9.3l-1.4-1.4L9 12.2V0H7v12.2L2.7 7.9 1.3 9.3 8 16z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'arrow-left': {
    html: '<path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'arrow-right': {
    html: '<path d="M9.3 1.3L7.9 2.7 12.2 7H0v2h12.2l-4.3 4.3 1.4 1.4L16 8z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'arrow-up': {
    html: '<path d="M1.3 6.7l1.4 1.4L7 3.8V16h2V3.8l4.3 4.3 1.4-1.4L8 0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  bell: {
    html:
      '<path d="M10 14H6c0 1.1.9 2 2 2s2-.9 2-2zM15 11h-.5c-.7-.7-1.5-1.7-1.5-3V5c0-2.8-2.2-5-5-5S3 2.2 3 5v3c0 1.3-.8 2.3-1.5 3H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  bolt: {
    html:
      '<path d="M15.716 7.504l-3.429-6A.996.996 0 0011.42 1H4.58a.999.999 0 00-.868.504l-3.429 6a.998.998 0 000 .992l3.429 6A.999.999 0 004.58 15h6.839c.359 0 .69-.192.868-.504l3.429-6a.998.998 0 000-.992zM8 11a3 3 0 110-6 3 3 0 010 6z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  bug: {
    html:
      '<path d="M16 4V3h-2v1c0 .6-.4 1-1 1h-1c-.3-.4-.6-.7-1-1V3c0-1.7-1.3-3-3-3S5 1.3 5 3v1c-.4.3-.7.6-1 1H3c-.6 0-1-.4-1-1V3H0v1c0 1.7 1.3 3 3 3h.1c-.1.3-.1.7-.1 1H0v2h3v1c-1.7 0-3 1.3-3 3v1h2v-1c0-.6.4-1 1-1h.4c.8 1.8 2.6 3 4.6 3s3.8-1.2 4.6-3h.4c.6 0 1 .4 1 1v1h2v-1c0-1.7-1.3-3-3-3v-1h3V8h-3c0-.3 0-.7-.1-1h.1c1.7 0 3-1.3 3-3z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'chart-peak': {
    html:
      '<path d="M9 0H7c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM3 10H1c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1zM15 5h-2c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'check-all': {
    html:
      '<path d="M4 14a1 1 0 01-.707-.293l-3-3a1 1 0 111.414-1.414l2.172 2.173 7.312-10.054a1 1 0 011.618 1.176l-8 11a1 1 0 01-.731.412H4zM13 4h3v2h-3zM10 8h6v2h-6zM7 12h9v2H7z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  check: {
    html:
      '<path d="M14.3 2.3L5 11.6 1.7 8.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l10-10c.4-.4.4-1 0-1.4s-1-.4-1.4 0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'chevron-down': {
    html:
      '<path d="M8 12a.997.997 0 01-.651-.241L-.41 5.109.892 3.59 8 9.683l7.108-6.093 1.302 1.519-7.759 6.65A.997.997 0 018 12z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'chevron-left': {
    html:
      '<path d="M10.892 16.41L4.241 8.65a1 1 0 010-1.301l6.651-7.76L12.411.891 6.317 8l6.093 7.108-1.518 1.302z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'chevron-right': {
    html:
      '<path d="M5.108 16.41L3.59 15.108 9.683 8 3.59.892 5.108-.41l6.651 7.76a1 1 0 010 1.301L5.108 16.41z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'chevron-up': {
    html:
      '<path d="M15.108 12.41L8 6.317.892 12.41-.41 10.891l7.759-6.65a.998.998 0 011.302 0l7.759 6.65-1.302 1.519z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  chip: {
    html:
      '<g><path d="M8 8h8v8H8z"></path><path d="M24 9V7h-3V5a2 2 0 00-2-2h-2V0h-2v3h-2V0h-2v3H9V0H7v3H5a2 2 0 00-2 2v2H0v2h3v2H0v2h3v2H0v2h3v2a2 2 0 002 2h2v3h2v-3h2v3h2v-3h2v3h2v-3h2a2 2 0 002-2v-2h3v-2h-3v-2h3v-2h-3V9zm-6 9H6V6h12z"></path></g>',
    info: { viewBox: '0 0 24 24', width: '16', height: '16' },
  },
  close: {
    html:
      '<path d="M14.7 1.3c-.4-.4-1-.4-1.4 0L8 6.6 2.7 1.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L6.6 8l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L8 9.4l5.3 5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L9.4 8l5.3-5.3c.4-.4.4-1 0-1.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  cloud: {
    html:
      '<path d="M13.1 13c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9C12.9 4.3 10.5 2 7.6 2S2.3 4.3 2.2 7.2C.9 7.6 0 8.7 0 10.1 0 11.7 1.3 13 2.9 13h10.2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  code: {
    html:
      '<path d="M12.7 11.7l-1.4-1.4L13.6 8l-2.3-2.3 1.4-1.4 3 3c.4.4.4 1 0 1.4l-3 3zM3.3 11.7l-3-3c-.4-.4-.4-1 0-1.4l3-3 1.4 1.4L2.4 8l2.3 2.3-1.4 1.4zM6 15c-.1 0-.2 0-.3-.1-.5-.2-.8-.7-.6-1.3l4-12c.2-.5.7-.8 1.3-.6.5.2.8.7.6 1.3l-4 12c-.2.4-.6.7-1 .7z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  compare: {
    html: '<path d="M13 4L9 8l4 4V9h3V7h-3zM7 8L3 4v3H0v2h3v3z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'credit-card': {
    html:
      '<path d="M16 5V3c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v2h16zM0 7v6c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V7H0zm6 4H2v-1h4v1zm8 0h-2v-1h2v1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  database: {
    html:
      '<g><path d="M8 16c3.5 0 7-.9 7-3V6.4C13.4 7.4 11 8 8 8s-5.4-.6-7-1.6V13c0 2.1 3.5 3 7 3z"></path><path d="M8 6c3.5 0 7-.9 7-3s-3.5-3-7-3-7 .9-7 3 3.5 3 7 3z"></path></g>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'download-file': {
    html:
      '<path d="M9 9V0H7v9H4l4 4 4-4z"></path><path d="M15 16H1a1 1 0 01-.993-1.124l1-8A1 1 0 012 6h3v2H2.883l-.75 6h11.734l-.75-6H11V6h3a1 1 0 01.992.876l1 8A.998.998 0 0115 16z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'drag-handle': {
    html: '<path d="M0 0h2v2H0zm4 0h2v2H4zM0 4h2v2H0zm4 0h2v2H4zM0 8h2v2H0zm4 0h2v2H4zm-4 4h2v2H0zm4 0h2v2H4z"></path>',
    info: { viewBox: '0 0 6 14', width: '6', height: '14' },
  },
  'electronic-circuit': {
    html:
      '<path d="M5.707 11.293L3.926 9.512a2.033 2.033 0 10-1.414 1.414L4 12.414V15a1 1 0 002 0v-3a1 1 0 00-.293-.707zM14 7a1.976 1.976 0 00-1.926 2.512l-1.781 1.781A1 1 0 0010 12v3a1 1 0 002 0v-2.586l1.488-1.488A2 2 0 1014 7z"></path><path d="M13 2a2 2 0 10-3 1.723v1.863l-2 2-2-2V3.723a2 2 0 10-2 0V6a1 1 0 00.293.707L7 9.414V15a1 1 0 002 0V9.414l2.707-2.707A1 1 0 0012 6V3.723A1.994 1.994 0 0013 2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  envelope: {
    html:
      '<path d="M15 1H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V2a1 1 0 00-1-1zm-1 12H2V6.723l5.504 3.145a.998.998 0 00.992 0L14 6.723V13zm0-8.58L8 7.849 2 4.42V3h12v1.42z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'file-copy': {
    html:
      '<path d="M15 4H1c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 10H2V6h12v8zM2 0h12v2H2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'file-text': {
    html: '<path d="M15 15V5l-5-5H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1zM3 2h6v4h4v8H3V2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'filter-organization': {
    html:
      '<path d="M15 10H7a1 1 0 00-1 1v4a1 1 0 001 1h8a1 1 0 001-1v-4a1 1 0 00-1-1zM5 12H2V6h3V4H2V1a1 1 0 00-2 0v12a1 1 0 001 1h4v-2zM15 2H7a1 1 0 00-1 1v4a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  funnel: {
    html:
      '<path d="M16 3c0-2.916-7.181-3-8-3S0 .084 0 3c0 .457.191.837.492 1.166L6 10.37V15a1 1 0 001 1h2a1 1 0 001-1v-4.63l5.47-6.163C15.794 3.87 16 3.476 16 3zM8 2c3.137 0 5.155.584 5.829 1-.674.416-2.692 1-5.829 1s-5.155-.584-5.829-1C2.845 2.584 4.863 2 8 2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  gear: {
    html:
      '<path d="M15.135 6.784a2 2 0 01-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094A2 2 0 019.216.865C9.065.258 8.669 0 8.219 0h-.438c-.45 0-.845.258-.997.865a2 2 0 01-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 01-1.23 2.969C.26 6.935 0 7.33 0 7.781v.438c0 .45.258.845.865.997a2 2 0 011.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 012.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 012.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.78.094-1.316a2 2 0 011.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997zM8 11a3 3 0 110-6 3 3 0 010 6z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  globe: {
    html:
      '<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm5.9 7H12c-.1-1.5-.4-2.9-.8-4.1 1.4.9 2.4 2.4 2.7 4.1zM8 14c-.6 0-1.8-1.9-2-5h4c-.2 3.1-1.4 5-2 5zM6 7c.2-3.1 1.3-5 2-5s1.8 1.9 2 5H6zM4.9 2.9C4.4 4.1 4.1 5.5 4 7H2.1c.3-1.7 1.3-3.2 2.8-4.1zM2.1 9H4c.1 1.5.4 2.9.8 4.1-1.4-.9-2.4-2.4-2.7-4.1zm9 4.1c.5-1.2.7-2.6.8-4.1h1.9c-.2 1.7-1.2 3.2-2.7 4.1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  help: {
    html:
      '<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1H7V9c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C5.9 3.6 6.9 3 8 3c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  hierarchy: {
    html:
      '<path d="M6 12h4v4H6zM6 0h4v4H6zM12 12h4v4h-4zM0 12h4v4H0zM3 9h4v2h2V9h4v2h2V8c0-.6-.4-1-1-1H9V5H7v2H2c-.6 0-1 .4-1 1v3h2V9z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  home: {
    html:
      '<path d="M11 16h2c.6 0 1-.4 1-1V8h1c.3 0 .6-.1.8-.3.4-.4.3-1-.1-1.4l-7-6C8.3 0 7.8 0 7.4.3l-7 6c-.4.4-.5 1-.1 1.4.1.2.4.3.7.3h1v7c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-3h4v3c0 .6.4 1 1 1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  image: {
    html:
      '<path d="M15 16H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h14c.6 0 1 .4 1 1v14c0 .6-.4 1-1 1zM2 14h12V2H2v12z"></path><path d="M6 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM3 12l2-4 2 2 3-4 3 6z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  info: {
    html:
      '<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  key: {
    html:
      '<path d="M12.7 0L6.5 6.3C6 6.1 5.5 6 5 6c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-.5-.1-1.1-.3-1.6L11 8V6h2V4h2l1-1V0h-3.3zM4.5 12c-.8 0-1.5-.7-1.5-1.5S3.7 9 4.5 9 6 9.7 6 10.5 5.3 12 4.5 12z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  laptop: {
    html:
      '<path d="M15 11V4c0-1.136-.864-2-2-2H3c-1.136 0-2 .864-2 2v7H0v1a2 2 0 002 2h12a2 2 0 002-2v-1h-1zM3 4h10v7H3V4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'large-broken-image': {
    html:
      '<path d="M21 5v6.59l-3-3.01-4 4.01-4-4-4 4-3-3.01V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zm-3 6.42l3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l3 2.99 4-4 4 4 4-3.99z"></path>',
    info: { viewBox: '0 0 24 24', width: '24', height: '24' },
  },
  'large-logo-github': {
    html:
      '<path d="M12 .3C5.4.3 0 5.7 0 12.3c0 5.3 3.4 9.8 8.2 11.4.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.5-1.4-1.3-1.8-1.3-1.8-1.2-.7 0-.7 0-.7 1.2.1 1.8 1.2 1.8 1.2 1.1 1.8 2.8 1.3 3.5 1 .1-.8.4-1.3.8-1.6-2.7-.3-5.5-1.3-5.5-5.9 0-1.3.5-2.4 1.2-3.2 0-.4-.5-1.6.2-3.2 0 0 1-.3 3.3 1.2 1-.3 2-.4 3-.4s2 .1 3 .4c2.3-1.6 3.3-1.2 3.3-1.2.7 1.7.2 2.9.1 3.2.8.8 1.2 1.9 1.2 3.2 0 4.6-2.8 5.6-5.5 5.9.4.4.8 1.1.8 2.2v3.3c0 .3.2.7.8.6 4.8-1.6 8.2-6.1 8.2-11.4C24 5.7 18.6.3 12 .3z"></path>',
    info: { viewBox: '0 0 24 24', width: '24', height: '24' },
  },
  'large-menu': {
    html:
      '<path d="M23 13H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1zM23 6H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1zM23 20H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1z"></path>',
    info: { viewBox: '0 0 24 24', width: '24', height: '24' },
  },
  link: {
    html:
      '<path d="M11 0C9.7 0 8.4.5 7.5 1.5L6.3 2.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l1.2-1.2c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.4.9 2.2s-.3 1.6-.9 2.1L12 8.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.2-1.2C15.5 7.6 16 6.3 16 5s-.5-2.6-1.5-3.5C13.6.5 12.3 0 11 0zM8.3 12l-1.2 1.2c-1.1 1.1-3.1 1.1-4.2 0-.6-.6-.9-1.4-.9-2.2s.3-1.6.9-2.1L4 7.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L1.5 7.5C.5 8.4 0 9.7 0 11s.5 2.6 1.5 3.5c.9 1 2.2 1.5 3.5 1.5s2.6-.5 3.5-1.5l1.2-1.2c.4-.4.4-1 0-1.4s-1-.3-1.4.1z"></path><path d="M9.4 5.2L5.2 9.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.2-4.2c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  list: {
    html:
      '<path d="M5 9h11v2H5zM0 9h3v2H0zM5 13h6v2H5zM0 13h3v2H0zM5 5h7v2H5zM0 5h3v2H0zM5 1h11v2H5zM0 1h3v2H0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'log-out': {
    html:
      '<path d="M4 4L0 8l4 4V9h6V7H4z"></path><path d="M15 16H6c-.6 0-1-.4-1-1v-3h2v2h7V2H7v2H5V1c0-.6.4-1 1-1h9c.6 0 1 .4 1 1v14c0 .6-.4 1-1 1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'logo-google-color': {
    html:
      '<path fill="#4285F4" d="M14.987 8.164c0-.474-.028-.697-.112-1.164H8.147v2.433h3.846a3.227 3.227 0 01-1.423 2.12v1.744h2.295c1.347-1.215 2.122-3.01 2.122-5.133z"></path><path fill="#34A853" d="M8.147 14.982c1.92 0 3.54-.618 4.72-1.685l-2.295-1.743c-.638.425-1.462.667-2.423.667-1.858 0-3.433-1.229-3.998-2.88H1.782v1.797c1.21 2.357 3.672 3.844 6.365 3.844z"></path><path fill="#FBBC04" d="M4.148 9.341a4.1 4.1 0 010-2.672V4.872H1.782a6.859 6.859 0 000 6.266l2.366-1.797z"></path><path fill="#EA4335" d="M8.147 3.787a3.914 3.914 0 012.732 1.046l2.033-1.992a6.923 6.923 0 00-4.765-1.815c-2.693 0-5.156 1.487-6.365 3.846l2.366 1.797c.563-1.654 2.14-2.882 3.999-2.882z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'logo-shopify-color': {
    html:
      '<g><path fill="#95BF47" d="M39.845 9.36c-.034-.242-.24-.38-.413-.38s-3.854-.275-3.854-.275-2.546-2.546-2.855-2.821c-.276-.275-.826-.207-1.033-.138-.034 0-.55.172-1.445.448-.86-2.512-2.374-4.783-5.024-4.783h-.24C24.258.447 23.295 0 22.503 0c-6.193 0-9.152 7.742-10.081 11.665-2.41.757-4.13 1.273-4.336 1.341-1.342.413-1.376.448-1.548 1.72C6.4 15.69 2.89 42.874 2.89 42.874L30.245 48l14.83-3.2c0-.034-5.195-35.2-5.23-35.44zM28.731 6.605a79.22 79.22 0 00-2.305.723v-.516c0-1.514-.207-2.753-.55-3.75 1.376.206 2.27 1.754 2.855 3.543zm-4.576-3.2c.378.964.62 2.306.62 4.164v.275c-1.515.482-3.132.964-4.784 1.48.93-3.544 2.684-5.265 4.164-5.919zM22.33 1.652c.275 0 .55.103.792.275-1.996.929-4.095 3.269-4.99 7.983-1.307.413-2.58.791-3.785 1.17 1.033-3.579 3.544-9.428 7.983-9.428z"></path><path fill="#5E8E3E" d="M39.432 8.946c-.172 0-3.854-.275-3.854-.275s-2.546-2.546-2.855-2.822a.553.553 0 00-.379-.172L30.28 48l14.83-3.2S39.914 9.6 39.88 9.36a.596.596 0 00-.448-.414z"></path><path fill="#FFF" d="M25.222 17.135l-1.824 5.437s-1.617-.86-3.579-.86c-2.89 0-3.028 1.823-3.028 2.27 0 2.478 6.47 3.442 6.47 9.257 0 4.576-2.891 7.535-6.814 7.535-4.714 0-7.088-2.925-7.088-2.925l1.273-4.163s2.478 2.133 4.542 2.133c1.377 0 1.927-1.066 1.927-1.858 0-3.234-5.299-3.372-5.299-8.705 0-4.473 3.2-8.809 9.703-8.809 2.478-.034 3.717.688 3.717.688z"></path></g>',
    info: { viewBox: '0 0 48 48', width: '16', height: '16' },
  },
  'logo-shopify': {
    html:
      '<path d="M13.3 3.1c0-.1-.1-.1-.2-.1s-1.3-.1-1.3-.1-.8-.9-.9-1c-.1-.1-.3-.1-.3 0 0 0-.2.1-.5.1C9.8 1.2 9.5.5 8.3.5 8.1.1 7.8 0 7.5 0c-2.1 0-3 2.6-3.4 3.9-.8.2-1.3.4-1.4.4-.4.2-.5.2-.5.6 0 .3-1.2 9.4-1.2 9.4l9.1 1.7 4.9-1.1S13.3 3.2 13.3 3.1zm-5-.5c-.5.2-1 .3-1.6.5.3-1.2.9-1.8 1.4-2 .2.9.2 1.5.2 1.5zm-.9-2c.1 0 .2 0 .3.1-.6.3-1.4 1-1.7 2.6-.4.1-.8.3-1.2.4C5.1 2.5 6 .6 7.4.6zm.4 6.9s-.5-.3-1.2-.3c-1 0-1 .6-1 .8 0 .8 2.2 1.1 2.2 3.1 0 1.5-1 2.5-2.3 2.5-1.6 0-2.4-1-2.4-1l.4-1.4s.8.7 1.5.7c.5 0 .6-.4.6-.6 0-1.1-1.8-1.1-1.8-2.9 0-1.5 1.1-2.9 3.2-2.9.8 0 1.2.2 1.2.2l-.4 1.8zm1-5.1v-.2c0-.5-.1-.9-.2-1.2.5.1.8.6 1 1.2-.2.1-.5.2-.8.2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  map: {
    html:
      '<path d="M14.6.1L10 1.9 6.4.1c-.2-.1-.5-.1-.8 0l-5 2c-.4.1-.6.5-.6.9v12c0 .7.7 1.2 1.4.9L6 14.1l3.6 1.8c.3.1.5.1.8 0l5-1.9c.4-.1.6-.5.6-.9V1c0-.7-.7-1.2-1.4-.9zM7 2.6l2 1v9.8l-2-1V2.6zM2 3.7l3-1.2v9.8l-3 1.2V3.7zm12 8.7l-3 1.1V3.7l3-1.2v9.9z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'menu-dots-vertical': {
    html:
      '<circle cx="8" cy="2" r="2"></circle><circle cx="8" cy="8" r="2"></circle><circle cx="8" cy="14" r="2"></circle>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'menu-dots': {
    html:
      '<circle cx="2" cy="8" r="2"></circle><circle cx="8" cy="8" r="2"></circle><circle cx="14" cy="8" r="2"></circle>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  menu: {
    html:
      '<path d="M15 7H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 1H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 13H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  moon: {
    html: '<path d="M6 0C2.5.9 0 4.1 0 7.9 0 12.4 3.6 16 8.1 16c3.8 0 6.9-2.5 7.9-6C9.9 11.7 4.3 6.1 6 0z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'move-up': {
    html:
      '<path d="M6 0H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V1a1 1 0 00-1-1zM6 9H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-4 2h3v3H2v-3zM13 5v10a1 1 0 01-2 0V5H8l4-5 4 5h-3z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  notes: {
    html:
      '<path d="M15 1h-3v2h2v11H2V3h2V1H1c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1z"></path><path d="M5 0h6v4H5z"></path><path d="M4 6h8v2H4zM4 10h8v2H4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  palette: {
    html:
      '<path d="M13.9 3.8c-3.6-1.7-6.6 1.8-8 0-.5-.7.7-2 0-2.9C5.4.3 4.2.4 3.5.9 1.4 2.4 0 4.8 0 7.5c0 4.4 3.6 8 8 8 3.7 0 6.8-2.5 7.7-5.9.4-1.1.8-4.5-1.8-5.8zM3 8c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm2 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm4 2c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm3-4c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  pencil: {
    html:
      '<path d="M8.1 3.5L.3 11.3c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3l7.8-7.8-4.4-4.4z"></path><path d="M15.7 3.3l-3-3c-.4-.4-1-.4-1.4 0L9.5 2.1l4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'pin-add': {
    html:
      '<path d="M12 0C7.576 0 3 3.366 3 9c0 5.289 7.951 13.363 8.29 13.705a1 1 0 001.42 0C13.049 22.363 21 14.289 21 9c0-5.634-4.576-9-9-9zm4 10h-3v3h-2v-3H8V8h3V5h2v3h3v2z"></path>',
    info: { viewBox: '0 0 24 24', width: '16', height: '16' },
  },
  'pin-remove': {
    html:
      '<path d="M12 0C7.576 0 3 3.366 3 9c0 5.289 7.951 13.363 8.29 13.705a1 1 0 001.42 0C13.049 22.363 21 14.289 21 9c0-5.634-4.576-9-9-9zm4 10H8V8h8v2z"></path>',
    info: { viewBox: '0 0 24 24', width: '16', height: '16' },
  },
  privacy: {
    html:
      '<path d="M9.25 5.5a1.25 1.25 0 00-2.5 0V7h2.5z"></path><path d="M14.165 1.014l-6-1a1.015 1.015 0 00-.33 0l-6 1A1 1 0 001 2v7a7 7 0 0014 0V2a1 1 0 00-.835-.986zM12 11a1 1 0 01-1 1H5a1 1 0 01-1-1V8a1 1 0 011-1h.25V5.5a2.75 2.75 0 015.5 0V7H11a1 1 0 011 1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  refresh: {
    html:
      '<path d="M4.5 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.3.4 5.9.4 3.1 3.1L.9.9.2 7.3l6.4-.7-2.1-2.1zM15.8 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2 1.8 0 3.6-.7 4.9-2l2.2 2.2.8-6.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'search-content': {
    html:
      '<path d="M4 13H1a1 1 0 010-2h3v2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2zM4 8H1a1 1 0 010-2h3v2zM15.707 13.293l-2.274-2.274A3.947 3.947 0 0014 9c0-2.206-1.794-4-4-4S6 6.794 6 9s1.794 4 4 4c.74 0 1.424-.215 2.019-.567l2.274 2.274 1.414-1.414zM10 11c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  search: {
    html:
      '<path d="M12.7 11.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S0 3.2 0 7.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S4.3 2 7.1 2s5.1 2.3 5.1 5.1-2.3 5-5.1 5z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  selection: {
    html:
      '<path d="M0 6.016h4v4H0zM0 12h4v4H0zM6 0h4v4H6zM0 0h4v4H0zM12 0h4v4h-4zM6 6.016h4v4H6zM13 7h2v2h-2zM13 13h2v2h-2zM7 13h2v2H7z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'send-message': {
    html:
      '<path d="M3.6 9.6v4.9c0 .7.8 1 1.3.5l2.3-2.7 4.7 3.5c.4.3 1 .1 1.1-.4L16 .9c.1-.6-.4-1-1-.8L.5 5.9c-.5.2-.6.9-.2 1.3l1.6 1.2 4.6-2.2c.4-.2.7.3.4.5L3.6 9.6z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'server-rack': {
    html:
      '<path d="M15 7H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v5a1 1 0 01-1 1zM2 5h12V2H2z"></path><circle cx="12.5" cy="3.5" r=".5"></circle><path d="M3 3h4v1H3zM15 16H1a1 1 0 01-1-1v-5a1 1 0 011-1h14a1 1 0 011 1v5a1 1 0 01-1 1zM2 14h12v-3H2z"></path><circle cx="12.5" cy="12.5" r=".5"></circle><path d="M3 12h4v1H3z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  slider: {
    html:
      '<path d="M15 3h-4c-.6 0-1 .4-1 1s.4 1 1 1h4c.6 0 1-.4 1-1s-.4-1-1-1zM5 1c-1.3 0-2.4.9-2.8 2H1c-.6 0-1 .4-1 1s.4 1 1 1h1.2C2.6 6.1 3.7 7 5 7c1.7 0 3-1.3 3-3S6.7 1 5 1zM1 13h4c.6 0 1-.4 1-1s-.4-1-1-1H1c-.6 0-1 .4-1 1s.4 1 1 1zM15 11h-1.2c-.4-1.2-1.5-2-2.8-2-1.7 0-3 1.3-3 3s1.3 3 3 3c1.3 0 2.4-.9 2.8-2H15c.6 0 1-.4 1-1s-.4-1-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'small-arrow-down': {
    html: '<path d="M7 8.586V0H5v8.586l-3-3L.586 7l4.707 4.707a1 1 0 001.414 0L11.414 7 10 5.586z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-arrow-up': {
    html: '<path d="M5.293.293L.586 5 2 6.414l3-3V12h2V3.414l3 3L11.414 5 6.707.293a1 1 0 00-1.414 0z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-chevron-down': {
    html:
      '<path d="M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-chevron-left': {
    html:
      '<path d="M8.707.293a1 1 0 00-1.414 0l-5 5a1 1 0 000 1.414l5 5a1 1 0 001.414-1.414L4.414 6l4.293-4.293a1 1 0 000-1.414z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-chevron-right': {
    html:
      '<path d="M9.707 5.293l-5-5a1 1 0 00-1.414 1.414L7.586 6l-4.293 4.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-chevron-up': {
    html:
      '<path d="M6.707 2.293a1 1 0 00-1.414 0l-5 5a1 1 0 001.414 1.414L6 4.414l4.293 4.293a1 1 0 001.414-1.414z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-close': {
    html:
      '<path d="M10.707 1.293a1 1 0 00-1.414 0L6 4.586 2.707 1.293a1 1 0 00-1.414 1.414L4.586 6 1.293 9.293a1 1 0 101.414 1.414L6 7.414l3.293 3.293a1 1 0 001.414-1.414L7.414 6l3.293-3.293a1 1 0 000-1.414z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-copy': {
    html:
      '<path d="M9 1a1 1 0 00-1-1H1a1 1 0 00-1 1v8a1 1 0 001 1h3V4a1 1 0 011-1h4V1z"></path><rect width="6" height="7" x="6" y="5" rx=".5"></rect>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-filter': {
    html:
      '<path d="M11.941.264A.5.5 0 0011.5 0H.5a.5.5 0 00-.416.777L4 6.651V11.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V6.651L11.916.777a.5.5 0 00.025-.513z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-grid': {
    html: '<path d="M7 7h4v4H7zM1 7h4v4H1zM1 1h4v4H1zM7 1h4v4H7z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-launch-app': {
    html:
      '<g><path d="M8.293 2.293L4.586 6 6 7.414l3.707-3.707L12 6V0H6l2.293 2.293z"></path><path d="M10 10H2V2h2V0H2a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V8h-2z"></path></g>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-list': {
    html: '<path d="M0 1h4v4H0zM6 2h6v2H6zM0 7h4v4H0zM6 8h6v2H6z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-move-vertical': {
    html: '<path d="M2.5 4h7L6 0 2.5 4zM6 12l3.5-4h-7L6 12z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-search': {
    html:
      '<path d="M11.707 10.293l-2.54-2.54a5.015 5.015 0 10-1.414 1.414l2.54 2.54a1 1 0 001.414-1.414zM2 5a3 3 0 113 3 3 3 0 01-3-3z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-star-half': {
    html:
      '<path d="M5.552.536L3.968 3.744l-3.54.514a.5.5 0 00-.277.853l2.562 2.5-.606 3.526a.5.5 0 00.726.527L6 10V.281a.488.488 0 00-.448.255z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-star': {
    html:
      '<path d="M11.572 4.27l-3.54-.514L6.448.548a.521.521 0 00-.9 0l-1.58 3.208-3.54.514a.5.5 0 00-.277.853l2.562 2.5-.606 3.526a.5.5 0 00.726.527L6 10.008l3.167 1.665a.488.488 0 00.232.057.5.5 0 00.494-.584L9.287 7.62l2.562-2.5a.5.5 0 00-.277-.853z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  'small-upload': {
    html:
      '<path d="M5 8h2V4h2L6 0 3 4h2v4z"></path><path d="M10.97 6.757A1 1 0 0010 6H8v2h1.219l.5 2H2.281l.5-2H4V6H2a1 1 0 00-.97.757l-1 4A1 1 0 001 12h10a1 1 0 00.97-1.243z"></path>',
    info: { viewBox: '0 0 12 12', width: '12', height: '12' },
  },
  speedometer: {
    html:
      '<g><path d="M7.061 7.061a1.5 1.5 0 000-2.122C6.475 4.354 1 1 1 1s3.354 5.475 3.939 6.061a1.5 1.5 0 002.122 0z"></path><path d="M6 0a1 1 0 000 2 4 4 0 11-4 4 1 1 0 00-2 0 6 6 0 106-6z"></path></g>',
    info: { viewBox: '0 0 12 12', width: '16', height: '16' },
  },
  spinner: {
    html:
      '<g><path fill-opacity=".2" d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 2a6 6 0 110 12A6 6 0 018 2z"></path><path d="M8 0a8 8 0 018 8h-2a6 6 0 00-6-6z"></path><animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 8 8" to="360 8 8" dur="0.5s" repeatCount="indefinite"></animateTransform></g>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  split: {
    html:
      '<path d="M3.414 2H6V0H1a1 1 0 00-1 1v5h2V3.414l5 5V16h2V7.586L3.414 2zM15 0h-5v2h2.586L9.414 5.172l1.414 1.414L14 3.414V6h2V1a1 1 0 00-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  star: {
    html:
      '<path d="M15.144 5.439l-4.317-.628L8.9.9a1.041 1.041 0 00-1.8 0L5.173 4.812l-4.317.627A1 1 0 00.3 7.145l3.123 3.045-.737 4.3a1 1 0 001.451 1.054L8 13.513l3.861 2.029a1 1 0 001.451-1.054l-.737-4.3L15.7 7.145a1 1 0 00-.554-1.705z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  success: {
    html:
      '<path d="M8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm3.613 4.21a1.002 1.002 0 00-1.405.177l-3.31 5.107-1.201-1.2a1.002 1.002 0 00-1.404.012 1 1 0 00-.012 1.402l2.003 2c.188.187.442.293.708.293h.062c.287-.018.553-.16.729-.386l4.006-6a1 1 0 00-.176-1.404z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  sun: {
    html:
      '<path d="M7 0h2v2H7zM12.88 1.637l1.414 1.415-1.415 1.413-1.414-1.414zM14 7h2v2h-2zM12.95 14.433l-1.415-1.414 1.414-1.414 1.415 1.413z"></path><path d="M7 14h2v2H7z"></path><path d="M2.98 14.363L1.566 12.95l1.415-1.414 1.414 1.415z"></path><path d="M0 7h2v2H0z"></path><path d="M3.05 1.707L4.465 3.12 3.05 4.535 1.636 3.121zM8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  swap: {
    html:
      '<path d="M11 3H1c-.6 0-1 .4-1 1s.4 1 1 1h10v3l5-4-5-4v3zM5 13h10c.6 0 1-.4 1-1s-.4-1-1-1H5V8l-5 4 5 4v-3z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'tags-stack': {
    html:
      '<path d="M15.707 7.207L8.5 0H3v2h3.914l7.5 7.5a2.012 2.012 0 01.187.227l1.106-1.106a1 1 0 000-1.414z"></path><path d="M13.707 10.207L6.5 3H1v5.5l7.207 7.207a1 1 0 001.414 0l4.086-4.086a1 1 0 000-1.414zM4 7a1 1 0 111-1 1 1 0 01-1 1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'time-clock': {
    html:
      '<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"></path><path d="M9 4H7v5h5V7H9z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'trash-can': {
    html:
      '<path d="M15 3h-3V1a1 1 0 00-1-1H5a1 1 0 00-1 1v2H1a1 1 0 000 2h14a1 1 0 000-2zM6 3V2h4v1zM2.055 7l.45 8.1a1 1 0 001 .9h9a1 1 0 00.995-.9l.45-8.1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'trend-up': {
    html: '<path d="M9 6h3.6L9 9.6l-4-4-4.7 4.7 1.4 1.4L5 8.4l4 4 5-5V11h2V4H9z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  undo: {
    html:
      '<path d="M8 5a10.07 10.07 0 00-6 2.017V5H0v5a1 1 0 001 1h5V9H2.722A8.058 8.058 0 018 7a8.06 8.06 0 015.657 2.343l1.414-1.414A10.068 10.068 0 008 5z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  upload: {
    html:
      '<g><path d="M9 4v7H7V4H4l4-4 4 4z"></path><path d="M15 16H1a1 1 0 01-.993-1.124l1-8A1 1 0 012 6h3v2H2.883l-.75 6h11.734l-.75-6H11V6h3a1 1 0 01.992.876l1 8A.998.998 0 0115 16z"></path></g>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  users: {
    html:
      '<path d="M14.864 10.216l-2.557-.639a.5.5 0 01-.369-.388l-.191-.963A3 3 0 0013.5 5.5V4.126A3.084 3.084 0 0010.592 1 3 3 0 007.97 2.393 4.207 4.207 0 019 5.128a4.239 4.239 0 00.584 2.457 1.5 1.5 0 01-.534 2.1l.137.04A2.51 2.51 0 0111 12.131V14.5a1.483 1.483 0 01-.092.5H15.5a.5.5 0 00.5-.5v-2.829a1.5 1.5 0 00-1.136-1.455z"></path><path d="M8.912 10.689l-2.157-.616a.5.5 0 01-.338-.325l-.17-.522a4.949 4.949 0 002.3-.4.5.5 0 00.192-.712A5.233 5.233 0 018 5.131 3.093 3.093 0 005.119 2 3 3 0 002 5a5.374 5.374 0 01-.736 3.115.5.5 0 00.193.711 4.949 4.949 0 002.3.4l-.17.522a.5.5 0 01-.338.325l-2.157.616A1.5 1.5 0 000 12.131V14.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-2.369a1.5 1.5 0 00-1.088-1.442z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  version: {
    html:
      '<path d="M3.414 2H6V0H1a1 1 0 00-1 1v5h2V3.414l5 5V16h2V7.586L3.414 2zM15 0h-5v2h2.586L9.414 5.172l1.414 1.414L14 3.414V6h2V1a1 1 0 00-1-1z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  warning: {
    html:
      '<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'window-code': {
    html:
      '<path d="M7.7 7.3l-5-5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L5.6 8l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5-5c.4-.4.4-1 0-1.4zM8 12h7v2H8z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
  'wrench-tool': {
    html:
      '<path d="M13.2 5.2l-2.4-2.4L13.2.4c-.5-.3-1.1-.4-1.7-.4C9 0 7 2 7 4.5c0 .5.1.9.2 1.4L.6 10.8c-.8.6-.8 1.7-.2 2.4l2.3 2.4c.7.7 1.8.6 2.4-.2L10 8.8c.5.1 1 .2 1.5.2C14 9 16 7 16 4.5c0-.6-.1-1.2-.3-1.7l-2.5 2.4z"></path>',
    info: { viewBox: '0 0 16 16', width: '16', height: '16' },
  },
};

export const iconList = Object.keys(icons) as Array<IconType>;

export type IconType =
  | 'account'
  | 'add-circle'
  | 'add'
  | 'ai-chip'
  | 'analytics'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'bell'
  | 'bolt'
  | 'bug'
  | 'chart-peak'
  | 'check-all'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'chip'
  | 'close'
  | 'cloud'
  | 'code'
  | 'compare'
  | 'credit-card'
  | 'database'
  | 'download-file'
  | 'drag-handle'
  | 'electronic-circuit'
  | 'envelope'
  | 'file-copy'
  | 'file-text'
  | 'filter-organization'
  | 'funnel'
  | 'gear'
  | 'globe'
  | 'help'
  | 'hierarchy'
  | 'home'
  | 'image'
  | 'info'
  | 'key'
  | 'laptop'
  | 'large-broken-image'
  | 'large-logo-github'
  | 'large-menu'
  | 'link'
  | 'list'
  | 'log-out'
  | 'logo-google-color'
  | 'logo-shopify-color'
  | 'logo-shopify'
  | 'map'
  | 'menu-dots-vertical'
  | 'menu-dots'
  | 'menu'
  | 'moon'
  | 'move-up'
  | 'notes'
  | 'palette'
  | 'pencil'
  | 'pin-add'
  | 'pin-remove'
  | 'privacy'
  | 'refresh'
  | 'search-content'
  | 'search'
  | 'selection'
  | 'send-message'
  | 'server-rack'
  | 'slider'
  | 'small-arrow-down'
  | 'small-arrow-up'
  | 'small-chevron-down'
  | 'small-chevron-left'
  | 'small-chevron-right'
  | 'small-chevron-up'
  | 'small-close'
  | 'small-copy'
  | 'small-filter'
  | 'small-grid'
  | 'small-launch-app'
  | 'small-list'
  | 'small-move-vertical'
  | 'small-search'
  | 'small-star-half'
  | 'small-star'
  | 'small-upload'
  | 'speedometer'
  | 'spinner'
  | 'split'
  | 'star'
  | 'success'
  | 'sun'
  | 'swap'
  | 'tags-stack'
  | 'time-clock'
  | 'trash-can'
  | 'trend-up'
  | 'undo'
  | 'upload'
  | 'users'
  | 'version'
  | 'warning'
  | 'window-code'
  | 'wrench-tool';
