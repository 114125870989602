/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Booleanish } from './types';

let win: Window | undefined;

// Note: Accessing "window" in IE11 is somewhat expensive, and calling "typeof window"
// hits a memory leak, whereas aliasing it and calling "typeof _window" does not.
// Caching the window value at the file scope lets us minimize the impact.
try {
  win = window;
} catch (e) {
  /* no-op */
}

/**
 * Helper to get the window object. The helper will make sure to use a cached variable
 * of "window", to avoid overhead and memory leaks in IE11.
 */
export const getWindow = (node?: HTMLElement | null) => node?.ownerDocument?.defaultView ?? win;

/**
 * Check if we can use the DOM. Useful for SSR purposes
 */
function checkIsBrowser() {
  const w = getWindow();
  return Boolean(typeof w !== 'undefined' && w.document && typeof w.document.createElement === 'function');
}

export const isBrowser = checkIsBrowser();

/**
 * Get the normalized event key across all browsers
 * @param event keyboard event
 */
export function normalizeEventKey(event: React.KeyboardEvent) {
  const { key, keyCode } = event;
  const isArrowKey = keyCode >= 37 && keyCode <= 40 && !key.startsWith('Arrow');

  return isArrowKey ? `Arrow${key}` : key;
}

export const dataAttr = (condition: boolean | undefined) => (condition ? '' : undefined) as Booleanish;

export const ariaAttr = (condition: boolean | undefined) => (condition ? true : undefined);

export const getOwnerDocument = (node?: HTMLElement) => node?.ownerDocument || document;

export const cx = (...classNames: any[]) => classNames.filter(Boolean).join(' ');

export function addDomEvent(
  target: EventTarget,
  eventName: string,
  handler: EventListener,
  options?: AddEventListenerOptions,
) {
  target.addEventListener(eventName, handler, options);
  return () => {
    target.removeEventListener(eventName, handler, options);
  };
}

export function getEventWindow(event: Event): typeof globalThis {
  return (((event as UIEvent).view ?? window) as unknown) as typeof globalThis;
}

export function isElement(el: any): el is Element {
  return el != null && typeof el === 'object' && 'nodeType' in el && el.nodeType === Node.ELEMENT_NODE;
}

export function isHTMLElement(el: any): el is HTMLElement {
  if (!isElement(el)) {
    return false;
  }

  const defaultView = el.ownerDocument.defaultView ?? window;
  return el instanceof defaultView.HTMLElement;
}

export function contains(parent: HTMLElement | null, child: HTMLElement) {
  if (!parent) return false;
  return parent === child || parent.contains(child);
}

export function getActiveElement(node?: HTMLElement) {
  const doc = getOwnerDocument(node);
  return doc?.activeElement as HTMLElement;
}

export function getRelatedTarget(event: Pick<FocusEvent, 'relatedTarget' | 'target' | 'currentTarget'>) {
  const target = (event.target ?? event.currentTarget) as HTMLElement;
  const activeElement = getActiveElement(target);
  return (event.relatedTarget ?? activeElement) as HTMLElement;
}
