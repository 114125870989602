import Box from 'components/Box';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Portal from 'components/Portal';
import VisuallyHidden from 'components/VisuallyHidden';
import React from 'react';
import { __DEV__, isString, omit, pick } from 'utils';

import { TooltipProps } from './types';
import { useTooltip, UseTooltipProps } from './use-tooltip';

/**
 * Tooltips display informative text when users hover, focus on, or tap an element.
 * @see WAI-ARIA https://www.w3.org/TR/wai-aria-practices/#tooltip
 */
const Tooltip = React.forwardRef((props: TooltipProps, ref: React.Ref<HTMLElement>) => {
  const {
    children,
    label,
    shouldWrapChildren,
    'aria-label': ariaLabel,
    arrowSize = 8,
    placement = 'top',
    hoverable = false,
    getPortalContainer,
    ...rest
  } = props;
  const { open, getTriggerProps, getTooltipProps, getArrowProps } = useTooltip({
    arrowSize,
    placement,
    hoverable,
    ...props,
  });
  let shouldWrap = isString(children) || shouldWrapChildren;
  // Ensure tooltip has only one child node if `shouldWrap` is false, otherwise default to `children`
  let child =
    (!shouldWrap && (React.Children.only(children) as React.ReactElement)) || (children as React.ReactElement);
  let trigger: React.ReactElement;

  // If the element is disabled and it's a button, disable pointer events, also automatically wrap
  if (child.props?.disabled) {
    if (child.type === 'button') {
      shouldWrap = true;
      child = React.cloneElement(child, {
        ...child.props,
        className: `pointer-events-none ${child.props?.className ?? ''}`,
      });
    } else if (child.type === Button || child.type === IconButton) {
      shouldWrap = true;
      child = React.cloneElement(child, {
        ...child.props,
        pointerEvents: 'pointer-events-none',
      });
    }
  }

  if (shouldWrap) {
    trigger = (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <span className="inline-block" tabIndex={0} {...getTriggerProps()}>
        {child}
      </span>
    );
  } else {
    trigger = React.cloneElement(child, getTriggerProps(child.props));
  }

  const hasAriaLabel = !!ariaLabel;
  const internalTooltipProps = getTooltipProps({ ...rest, ref });
  const tooltipProps = hasAriaLabel ? omit(internalTooltipProps, ['role', 'id']) : internalTooltipProps;
  const hiddenProps = pick(internalTooltipProps, ['role', 'id']);

  // If the `label` or `aria-label` is empty, there's no
  // point showing the tooltip. Let's simply return back the children
  if (!(label || ariaLabel)) {
    return <>{child}</>;
  }

  return (
    <>
      {trigger}

      {open && (
        <Portal getContainer={getPortalContainer}>
          <Box {...tooltipProps}>
            {label}

            {hasAriaLabel && <VisuallyHidden {...hiddenProps}>{ariaLabel}</VisuallyHidden>}

            <Box {...getArrowProps()} />
          </Box>
        </Portal>
      )}
    </>
  );
});

if (__DEV__) {
  Tooltip.displayName = 'Tooltip';
}

export type { TooltipProps, UseTooltipProps };
export { useTooltip };
export default Tooltip;
