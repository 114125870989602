import { useId } from '@reach/auto-id';
import Box from 'components/Box';
import { useInputStyle, UseInputStyleProps } from 'hooks';
import React from 'react';
import { __DEV__ } from 'utils';

import Label from '../Label';
import { SelectProps } from './types';

const Select = React.forwardRef((props: SelectProps, ref?: React.Ref<HTMLSelectElement>) => {
  const { id = `select-${useId()}`, children, disabled, invalid, placeholder, label, ...rest } = props;
  const styleProps = useInputStyle({ block: true, type: 'select', ...props } as UseInputStyleProps);

  return (
    <>
      {label && (
        <Label visuallyHidden htmlFor={id}>
          {label}
        </Label>
      )}

      <Box
        as="select"
        ref={ref}
        id={id}
        dir="auto"
        disabled={disabled}
        aria-invalid={invalid}
        dangerouslySetClassName="form-select"
        {...styleProps}
        {...rest}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </Box>
    </>
  );
});

if (__DEV__) {
  Select.displayName = 'Select';
}

export default Select;
export type { SelectProps };
