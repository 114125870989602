/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dict, Omit } from './types';

/**
 * Filter an object to keep or remove certain keys
 * @param input Object to filter
 * @param keys Keys that can be kept or removed
 * @param invert Whether the keys are kept (false) or remove (true)
 */
export function filterObject(input: Record<string, any>, keys: Array<string>, invert = false): Record<string, any> {
  return Object.keys(input)
    .filter((key) => (invert ? !keys.includes(key) : keys.includes(key)))
    .reduce((obj, key) => Object.assign(obj, { [key]: input[key] }), {});
}

export function omit<T extends Dict, K extends keyof T>(object: T, keys: K[]) {
  const result: Dict = {};

  for (const key in object) {
    // eslint-disable-next-line no-continue
    if (keys.includes(key as any)) continue;
    result[key] = object[key];
  }

  return result as Omit<T, K>;
}

export function pick<T extends Dict, K extends keyof T>(object: T, keys: K[]) {
  const result = {} as { [P in K]: T[P] };
  for (const key of keys) {
    if (key in object) {
      result[key] = object[key];
    }
  }
  return result;
}
