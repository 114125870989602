import { mapTruncateValue } from 'utils';

import { BoxProps } from '../Box';
import { HeadingElements, HeadingSizes } from './types';

const mapElementToSize = (element: HeadingElements): HeadingSizes => {
  switch (element) {
    case 'h6':
      return 'xs';
    case 'h5':
      return 'sm';
    case 'h4':
      return 'md';
    case 'h3':
      return 'lg';
    case 'h2':
      return 'xl';
    case 'h1':
      return '2xl';
    default:
      return 'base';
  }
};

interface UseHeadingStylesOptions {
  as: HeadingElements;
  size?: HeadingSizes;
  truncate?: boolean | 2 | 3 | 4 | 5;
}

export default function useHeadingStyles(options: UseHeadingStylesOptions): BoxProps {
  const { as, size = mapElementToSize(as), truncate = false } = options;
  const props: BoxProps = {
    truncate: mapTruncateValue(truncate),
    textColor: size === 'xs' ? 'text-gray-400' : 'text-gray-800',
  };

  if (size !== 'xs') {
    props.fontWeight = 'font-semibold';
    props.letterSpacing = 'tracking-tight';
  }

  switch (size) {
    case '2xl':
      props.fontSize = ['text-4xl', 'lg:text-5xl'];
      break;

    case 'xl':
      props.fontSize = ['text-3xl', 'lg:text-4xl'];
      break;

    case 'lg':
      props.fontSize = ['text-2xl', 'lg:text-3xl'];
      break;

    case 'md':
      props.fontSize = ['text-lg', 'lg:text-2xl'];
      break;

    case 'sm':
      props.fontSize = ['text-base', 'lg:text-lg'];
      break;

    case 'base':
      props.fontSize = 'text-base';
      break;

    case 'xs':
      props.fontSize = 'text-xs';
      props.fontWeight = 'font-medium';
      props.textTransform = 'uppercase';
      props.letterSpacing = 'tracking-wide';
      break;

    default:
      break;
  }

  return props;
}
